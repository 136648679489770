import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Basic/Button';
import { PanelElement } from '../style';
import { AppDispatch, RootState } from 'store';
import { suggestNewPanelAlignment } from 'store/slices/RoofDataSlice/roofDataSlice';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { ToolNewPostionSilce } from 'store/slices/ToolSlice/types';

const RecommendPanelsButton = React.memo(() => {
	const dispatch = useDispatch<AppDispatch>();
	const queriesRunningInBackGround = useQueriesRunningInBackGround();

	const { annualInputEnergy, selectedPanels: selectedPanelsOnUI } = useSelector(
		(state: RootState) => state.energyData.data as EnergyState
	);
	const { drawModeEnabled, editModeEnabled } = useSelector(
		(state: RootState) => state.toolNewPostions.data as ToolNewPostionSilce
	);
	const { recommendedPanels } = useSelector(
		(state: RootState) => state.roofData.data
	);

	const sortedSelectedPanelsOnUI = React.useMemo(() => [...selectedPanelsOnUI].sort(), [selectedPanelsOnUI]);
	const sortedSelectedPanels = React.useMemo(() => [...recommendedPanels ?? []].sort(), [recommendedPanels]);

	const isRecommendationNeeded = React.useMemo(
		() => !(
			sortedSelectedPanelsOnUI.length === sortedSelectedPanels.length &&
			sortedSelectedPanels.every((panel, index) => panel === sortedSelectedPanelsOnUI[index])
		),
		[sortedSelectedPanels, sortedSelectedPanelsOnUI]
	);

	// Memoize the recommendPanels function
	const recommendPanels = useCallback(async () => {
		await dispatch(suggestNewPanelAlignment({ energy: annualInputEnergy }));
	}, [dispatch, annualInputEnergy]);

	return (
		<PanelElement>
			<Button
				onClick={recommendPanels}
				disabled={queriesRunningInBackGround || drawModeEnabled || editModeEnabled || !isRecommendationNeeded}

				style={{
					borderRadius: '20px',
					color: '#64686A',
					background: '#fff',
					border: '1px solid #C7CACF',
					whiteSpace: 'nowrap',
				}}
			>
				<svg width="20" height="18" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.86143 17.0514H1V11.7229M6.86143 17.0514H10.5914M6.86143 17.0514V11.7229M6.86143 1H1V6.39429M6.86143 1H12.7229V7.99287V6.39429H1M6.86143 1V11.7229M1 6.39429V11.7229M1 11.7229H8.5H6.86143" stroke="#64686A" strokeWidth="1.5"/>
					<path d="M11.3173 12.0621C10.9757 13.3107 11.3245 14.7042 12.3053 15.6851C12.8264 16.2062 13.4904 16.5611 14.2132 16.7048C14.936 16.8486 15.6853 16.7748 16.3661 16.4928C17.047 16.2108 17.629 15.7332 18.0384 15.1204C18.4479 14.5076 18.6664 13.7872 18.6664 13.0502C18.6664 12.3132 18.4479 11.5928 18.0384 10.98C17.629 10.3672 17.047 9.88964 16.3661 9.60761C15.6853 9.32558 14.936 9.25179 14.2132 9.39557" stroke="#64686A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M12.6338 13.3796L11.3164 12.0621L9.99898 13.3796" stroke="#64686A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>

				Recommend panels
			</Button>
		</PanelElement>
	);
});

RecommendPanelsButton.displayName = 'RecommendPanelsButton';

export default RecommendPanelsButton;
