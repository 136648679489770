import { useCallback } from 'react';
import { Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { useKonvaScale } from 'hooks/useKonvaScale';

export default function SetbackArea() {

	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const scale = useKonvaScale();
	const transformHullCoords = useCallback((hullCoords: RasterRoofSegment['simplifiedHullCoords'] | undefined) => {
		return hullCoords?.flat().map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y) || [];
	}, [scale]);

	return (
		<>
			{
				allRoofSegs.map(rs => (
					(<Line
						key={rs.id}
						closed={true}
						stroke={'orange'}
						opacity={.3}
						strokeWidth={2}
						sceneFunc={(context, shape) => {

							if (!rs.simplifiedHullCoords?.length || !rs.setbackPolygonCoords?.length) return;

							const isClockwise = (points: number[][]) => {
								let sum = 0;
								for (let i = 0; i < points.length; i++) {
									const [x1, y1] = points[i];
									const [x2, y2] = points[(i + 1) % points.length];
							
									sum += (x2 - x1) * (y2 + y1);
								}
								return sum > 0;
							};

							const isWindingClockWise= isClockwise(rs.simplifiedHullCoords);
							
							const simplifiedFacetPolygon = rs.simplifiedHullCoords.slice();
							const setbackPolygon = rs.setbackPolygonCoords.slice();

							if(isWindingClockWise){
								simplifiedFacetPolygon.reverse();
							}

							const transformedSimplifiedFacetPolygon = transformHullCoords(simplifiedFacetPolygon);
							const transformedSetbackPolygon = transformHullCoords(setbackPolygon);

							const arePolygonsEqual = JSON.stringify(transformedSimplifiedFacetPolygon.flat().sort()) === 
									JSON.stringify(transformedSetbackPolygon.flat().sort());

							context.beginPath();
							context.moveTo(transformedSimplifiedFacetPolygon[0], transformedSimplifiedFacetPolygon[1]);
							for (let i = 2; i < transformedSimplifiedFacetPolygon.length; i = i + 2) {
								context.lineTo(transformedSimplifiedFacetPolygon[i], transformedSimplifiedFacetPolygon[i + 1]);
							}
							context.closePath();

							context.moveTo(transformedSetbackPolygon[0], transformedSetbackPolygon[1]);
							for (let i = 2; i < transformedSetbackPolygon.length; i = i + 2) {
								context.lineTo(transformedSetbackPolygon[i], transformedSetbackPolygon[i + 1]);
							}

							context.closePath();
							const shouldFill = !arePolygonsEqual;
							if(shouldFill){
								context.fillStyle = 'orange';
								context.fill();
							}
							context.fillStrokeShape(shape);
							
						}}
					/>))
				)
			}

		</>
	);

}