import { useKonvaScale } from 'hooks/useKonvaScale';
import { useMemo } from 'react';
import { Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export default function Obstructions() {

	const { obstructions } = useSelector((state: RootState) => state.roofData.data);
	const scale = useKonvaScale();

	const obstructionsList = useMemo(() => (obstructions?.map((obstructionCoords) => {
		return obstructionCoords.coordinates?.flat()?.map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y);
	})), [scale, obstructions]);

	if (!obstructions?.length) {
		return null;
	}

	return (
		<>
			{
				obstructionsList.map((obstruction, index) => {
					return (
						<Line
							key={index}
							points={obstruction}
							stroke={'red'}
							strokeWidth={1}
							closed={true}
						/>
					);
				})
			}
		</>
	);
}