import Button from 'components/Basic/Button';
import { useRefs } from 'contexts/RefContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { batchUpdateToolState } from 'store/slices/ToolSlice';
import { DEFAULT_KONVA_SCALE, DEFAULT_KONVA_STAGE_POSITION, PARENT_EVENTS } from '../../../../constants';
import { useEffect } from 'react';

export default function FullViewBtn() {

	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();

	useEffect(() => {
		function handleSaveDesignEvent(event: MessageEvent) {
			if (event.data === 'DISABLE_FULL_SCREEN_MODE') {
				dispatch(batchUpdateToolState({
					fullScreenEnabled: false, zoomLevel: DEFAULT_KONVA_SCALE, konvaStagePosition: DEFAULT_KONVA_STAGE_POSITION
				}));
			}
		}
		window.addEventListener('message', handleSaveDesignEvent);
		return () => {
			window.removeEventListener('message', handleSaveDesignEvent);
		};
	});
	
	function toggleFullScreen(enabled= fullScreenEnabled) {
		dispatch(batchUpdateToolState({ fullScreenEnabled: !enabled, zoomLevel: DEFAULT_KONVA_SCALE }));
		window.parent.postMessage(
			{ id: 'FULL_VIEW_MODE', detail: { fullScreenEnabled: !enabled } },
			'*'
		);
	}
	return (
		<Button 
			onClick={()=>toggleFullScreen()}
			style={{
				borderRadius: '100%',
				background: '#fff',
				borderColor: '#C7CACF',
				color: '#64686A',
				padding:'9.99px 10px'
			}}
			className={'fullscreenviewVisible btnHover ' + `${fullScreenEnabled ? ' active' : ''}`}
		>
			{!fullScreenEnabled && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 4.75V2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 
					1H4.75M13 9.25V11.5C13 11.8978 12.842 12.2794 12.5607 12.5607C12.2794 12.842 11.8978 13 11.5 
					13H9.25M9.25 1H11.5C11.8978 1 12.2794 1.15804 12.5607 1.43934C12.842 1.72064 13 2.10218 13 
					2.5V4.75M4.75 13H2.5C2.10218 13 1.72064 12.842 1.43934 12.5607C1.15804 12.2794 1 11.8978 
					1 11.5V9.25" stroke={`${fullScreenEnabled ? '#fff' : '#64686A'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>}
			{fullScreenEnabled && <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2 2L16 16M2 16L16 2" stroke="#64686A" strokeWidth="2.1" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
			}
		</Button>
	);

}