import { memo, useRef } from 'react';
import { SUBHUB_APP_NAME } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import CustomToggle from 'components/Basic/CustomToggle/CustomToggle';
import { showAllPanels as showAllPanelsAction } from 'store/slices/RoofDataSlice/roofDataSlice';
import { debounce } from '../../../util';
import { FlexDivRow } from '../style';

export default memo(function AllPanelsToggle() {
	const { org, showAllPanels } = useSelector((state: RootState) => state.roofData.data);
	const { parentAppName } = useSelector((state: RootState) => state.QueryParams.data );
	const appIsEmbeddedInSubhub = parentAppName === SUBHUB_APP_NAME;
	const showToggle = !appIsEmbeddedInSubhub && org.setting.showToggleForAllPanels;
	const toggleRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch<AppDispatch>();

	const toggleChecked = () => {
		if (toggleRef.current) {
			toggleRef.current.click();
			debouncedToggleHandler(toggleRef.current.checked);
		}
	};

	const debouncedToggleHandler = debounce((toggleValue: boolean) => {
		dispatch(showAllPanelsAction({ toggleValue }));
	}, 250);

	if(!showToggle) return null;

	return (
		<FlexDivRow className={` ${!org.setting?.isB2C ? 'panels-modes-wrap': 'panels-wrap' }`}>
			<label htmlFor="cheese-status">Show all panels</label>
			<CustomToggle
				ref={toggleRef}
				defaultChecked={showAllPanels}
				onChange={toggleChecked}
				styles={{
					height: '25px',
					width: '50px',
				}}
			/>
		</FlexDivRow>
	);
});
