import Button from 'components/Basic/Button';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { ButtonHTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type SaveDesignBtnProps = ButtonHTMLAttributes<HTMLButtonElement>
export default function SaveDesignBtn({ disabled, ...rest }: SaveDesignBtnProps) {
	const { uuid, org, roofDataChanging } = useSelector((state: RootState) => ({
		...state.roofData.data,
		roofDataChanging: state.roofData.configuringNewPanel || state.roofData.loading || state.roofData.switchingImageryProvider
	}));
	const saveDesignBtnText = org.setting?.dataForTool?.seeRecommendButtonText || 'Next';
	const allQueriesRunningInBackGround = useQueriesRunningInBackGround();
	const energyQueriesInProgrss = useQueriesRunningInBackGround(['save', `monthlyDetails-${uuid}`, `hourlyEnergy-${uuid}`]);
	const disableBtn = allQueriesRunningInBackGround || energyQueriesInProgrss || roofDataChanging || disabled;
	const showLoader = energyQueriesInProgrss;

	return <Button
		className="save-btn"
		disabled={disableBtn}
		showLoader={showLoader}
		{...rest}
	>
		{saveDesignBtnText}
		<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.793 0.292786C7.98053 0.105315 8.23484 0 8.5 0C8.76516 0 
				9.01947 0.105315 9.207 0.292786L13.707 4.79279C13.8945 4.98031 13.9998 
				5.23462 13.9998 5.49979C13.9998 5.76495 13.8945 6.01926 13.707 6.20679L9.207 
				10.7068C9.0184 10.8889 8.7658 10.9897 8.5036 10.9875C8.2414 10.9852 7.99059 
				10.88 7.80518 10.6946C7.61977 10.5092 7.5146 10.2584 7.51233 9.99619C7.51005 
				9.73399 7.61084 9.48139 7.793 9.29279L10.5 6.49979H1C0.734784 6.49979 
				0.48043 6.39443 0.292893 6.20689C0.105357 6.01936 0 5.765 0 5.49979C0 
				5.23457 0.105357 4.98022 0.292893 4.79268C0.48043 4.60514 0.734784 4.49979 1 
				4.49979H10.5L7.793 1.70679C7.60553 1.51926 7.50021 1.26495 7.50021 
				0.999786C7.50021 0.734622 7.60553 0.480314 7.793 0.292786Z" fill="#2E3B68" />
		</svg>
	</Button>;
}