import React, { InputHTMLAttributes, forwardRef } from 'react';
import { Switch, Togglebtn } from './style';
import { Interpolation } from 'styled-components';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	styles?: Interpolation<React.CSSProperties>;
}

const CustomToggle = forwardRef<HTMLInputElement, Props>((props, ref) => {
	const {styles, ...restInputProps} = props;
	return (
		<div className='toggle-button-cover all-panel-toggle'>
			<Switch styles={styles}>
				<input className='checkbox' ref={ref} type="checkbox" {...restInputProps}/>
				<Togglebtn className='toggleBtn'> 
					<span></span>
				</Togglebtn>
			</Switch>
		</div>
	);
});

CustomToggle.displayName = 'CustomToggle';
export default CustomToggle;