import { combineReducers, configureStore } from '@reduxjs/toolkit';
import roofDataReducer from 'store/slices/RoofDataSlice/roofDataSlice';
import energyProviderReducer from 'store/slices/EnergySlice/energySlice';
import offsetModelReducer from 'store/slices/OffsetModelSlice/offsetmodelSlice';
import authProviderReducer from 'store/slices/AuthSlice/authSlice';
import QueryParamReducer from 'store/slices/QueryParamsSlice';
import IrradianceReducer from 'store/slices/IrradianceSlice';
import ToolNewPostionReducer from 'store/slices/ToolSlice';
import DeleteModalReducer from 'store/slices/DeleteModalSlice';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import EditFacetModalReducer from 'store/slices/EditFacetModalSlice';
import ChangeOrientationModalSlice from './slices/ChangeOrientationModalSlice';
import WarningModalSlice from './slices/WarningSlice';
import ManualToolModalSlice from './slices/ManualToolModal';
import addPanelModalSlice from './slices/AddPanelModalSlice/addPanelModalSlice';
import InfoModalSlice from './slices/InfoSlice';
import PanelSlice from './slices/PanelSlice';
import postMessageMiddleware from './slices/RoofDataSlice/Middlewares/postMessageMiddleware';
import designSettingReducer from './slices/DesignSettingsSlice';

const persistConfig = {
	key: 'root',
	storage,
	stateReconciler: autoMergeLevel2,
	blacklist: [
		'toolNewPostions',
		'deleteModal',
		'editFacetModalReducer',
		'Irradiance',
		'changeOrientationModal',
		'warningModal',
		'manualToolModal',
		'panelSlice',
		'designSettingSlice'
	],
};

const rootReducer = combineReducers({
	roofData: roofDataReducer,
	energyData: energyProviderReducer,
	offsetModal: offsetModelReducer,
	authProvider: authProviderReducer,
	QueryParams: QueryParamReducer,
	Irradiance: IrradianceReducer,
	toolNewPostions: ToolNewPostionReducer,
	deleteModal: DeleteModalReducer,
	editFacetModalReducer: EditFacetModalReducer,
	changeOrientationModal: ChangeOrientationModalSlice,
	warningModal: WarningModalSlice,
	manualToolModal: ManualToolModalSlice,
	addPanelModal: addPanelModalSlice,
	infoModal: InfoModalSlice,
	panelSlice: PanelSlice,
	designSettigs: designSettingReducer
});

const persistedReducers = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

// const loggerMiddleware = store => next => action => {
// 	console.log('Dispatching:', action);
// 	console.log('Before State:', store.getState());
// 	const result = next(action);
// 	console.log('After State:', store.getState());
// 	return result;
// };

export const store = configureStore({
	reducer: persistedReducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
			},
		})
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch