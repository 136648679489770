import React, { useEffect, useRef } from 'react';
import { useRefDispatch, useRefs } from 'contexts/RefContext';
import RasterImage from './RasterImage';
import { Group } from 'react-konva';
import { Circle } from 'konva/lib/shapes/Circle';
import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { setAzimuthOrPitchForNewlyCreatedNrelFacets, setNewlyCreatedFacets, setUnfinalizedFacet } from 'store/slices/ToolSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { KonvaEventObject } from 'konva/lib/Node';
import { setDropdownState } from 'store/slices/EditFacetModalSlice';
import { calculateCentroidOfPolygon, calculateArrowPoints } from '../util';
import { DESIGN_MODES, KONVA_FACET_GROUP } from '../../../constants';

const distance = (a: number[], b: number[]) => Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2);

const lineConfig = {
	stroke: 'rgba(0,0,0,0.4)',
	strokeWidth: 3,
	fill: 'rgba(0,0,0,0.4)',
};

type DrawArrowProps = {
	polPoints: number[][];
	center: Vector2d;
	userFacetGroup: Konva.Group;
	konvaRef: React.RefObject<Konva.Stage>;
	dispatchCallback: (id: string, azimuth: number) => void,
};

type ArrowData = {
  angle: number;
  groupRefId: string;
  selected: boolean;
  center: Konva.Vector2d;
};
// Function to draw arrows with updated types
function DrawArrows({ polPoints, center, userFacetGroup, konvaRef, dispatchCallback }: DrawArrowProps) {
	// Function to create an arrow
	const createArrow = (points: number[], customData: ArrowData): Konva.Arrow =>
		new Konva.Arrow({
			points,
			pointerLength: 10,
			pointerWidth: 10,
			fill: 'yellow',
			stroke: 'yellow',
			strokeWidth: 5,
			hitStrokeWidth: 20,
			customData,
		});

	// Function to handle arrow click event
	const handleArrowClick = (arrow: Konva.Arrow, data: ArrowData) => {
		arrow.on('click', (e) => {
			e.cancelBubble = true;
			const { center, angle, groupRefId } = data;
			const centerArrow = findCenterArrow(groupRefId);

			dispatchCallback(groupRefId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0], angle);
			updateCenterArrow(arrow, center, angle, centerArrow, groupRefId);
			selectArrow(arrow, userFacetGroup);
		});

		arrow.on('mouseenter', () => {
			arrow.fill('blue');
			arrow.stroke('blue');
		});

		arrow.on('mouseleave', () => {
			arrow.fill('yellow');
			arrow.stroke('yellow');
		});
	};

	// Function to find center arrow
	const findCenterArrow = (groupRefId: string): Konva.Arrow | undefined =>
    konvaRef?.current?.findOne('#' + groupRefId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0] + 'centerarrow') as Konva.Arrow;

	// Function to update the center arrow
	const updateCenterArrow = (
		arrow: Konva.Arrow,
		center: Konva.Vector2d,
		angle: number,
		centerArrow: Konva.Arrow | undefined,
		groupRefId: string
	) => {
		const azimAngle = 90 - angle;
		const arrowEndX = center.x + 35 * Math.cos(azimAngle * (Math.PI / 180));
		const arrowEndY = center.y - 35 * Math.sin(azimAngle * (Math.PI / 180));

		arrow.visible(false);

		if (centerArrow) {
			centerArrow.points([center.x, center.y, arrowEndX, arrowEndY]);
			centerArrow.fill('blue');
			centerArrow.stroke('blue');
		} else {
			centerArrow = new Konva.Arrow({
				points: [center.x, center.y, arrowEndX, arrowEndY],
				pointerLength: 10,
				pointerWidth: 10,
				fill: 'yellow',
				stroke: 'yellow',
				strokeWidth: 5,
				hitStrokeWidth: 30,
				id: groupRefId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0] + KONVA_FACET_GROUP.CENTER_ARROW_ID_SUFFIX,
			});
			userFacetGroup.add(centerArrow);
		}
	};

	// Function to select arrow and handle visibility
	const selectArrow = (arrow: Konva.Arrow, group: Konva.Group) => {
		const data = arrow.getAttr('customData') as ArrowData;
		arrow.setAttr('customData', { ...data, selected: true });

		group.children?.forEach((child) => {
			if (child.getClassName() === 'Arrow' && child._id !== arrow._id) {
				const childData = child.getAttr('customData') as ArrowData;
				if (childData?.selected) {
					child.setAttr('customData', { ...childData, selected: false });
					child.visible(true);
				}
			}
		});
	};

	for (let i = 0; i < polPoints.length; i++) {
		const j = (i + 1) % polPoints.length;
		const ap = calculateArrowPoints([polPoints[i], polPoints[j]], center);

		const arrowData: ArrowData = {
			angle: ap.angle,
			groupRefId: userFacetGroup.id(),
			selected: false,
			center,
		};

		const arrow = createArrow([...ap.outwardPoints[0], ...ap.outwardPoints[1]], arrowData);

		// Find center arrow
		// if (i === 0) {
		const { angle, groupRefId } = arrowData;
		const centerArrow = findCenterArrow(groupRefId);

		dispatchCallback(groupRefId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0], angle);
		updateCenterArrow(arrow, center, angle, centerArrow, groupRefId);
		selectArrow(arrow, userFacetGroup);
		// }

		handleArrowClick(arrow, arrowData);
		userFacetGroup.add(arrow);
	}
}

type Props = {
	showAzimuthArrows: boolean;
}
export default function PanelDrawer(props : Props) {
	const { editModeEnabled, drawModeEnabled, moveStage, newlyCreatedFacets, 
		groundMountEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { mode } = useSelector((state: RootState) => state.roofData.data);
	const { konvaRef } = useRefs();
	const refDispatch = useRefDispatch();
	const groupRef = useRef<Konva.Group | null>(null);
	const userFacetGroup = useRef<Konva.Group | null>(null);
	const pointsRef = useRef<number[][]>([]);
	const lineRef = useRef<Konva.Line>(new Konva.Line(lineConfig));
	const pointLineRef = useRef<Konva.Line>(new Konva.Line(lineConfig));
	const isDrawing = useRef<boolean>(false);
	const longTouchTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const dispatch = useDispatch<AppDispatch>();
	const canDrawFacet = editModeEnabled && drawModeEnabled && !moveStage;

	// const panelRef = useRef<Konva.Rect | null>(null);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if ((event.ctrlKey && event.key === 'z') || event.key === 'Escape') {
				deleteLastLine();
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, []);

	useEffect(() => {
		refDispatch({ type: 'ADD_LAST_POLYGON_REF', payload: { ref: {
			groupRef:null,
			pointsRef:null,
			lineRef:null,
			pointLineRef:null,
		}} });
	}, [drawModeEnabled, refDispatch]);

	function deleteLastLine() {
		if (!pointsRef.current.length) return;

		const children = userFacetGroup.current?.children;
		pointLineRef.current.points([]);

		if (children && children.length > 1) {
			const lastChild = children[children.length - 1];
			if (lastChild instanceof Konva.Image || lastChild instanceof Konva.Line) return;
			lastChild.destroy();
		}

		const points = lineRef.current.points();
		if (points.length >= 2) {
			points.splice(-2);
			lineRef.current.points(points);
		}
		if (pointsRef.current.length > 0) {
			pointsRef.current.splice(-1);
		}
	}

	function dispatchNewlyCreatedFacets(facetPoints: number[][]) {
		dispatch(setNewlyCreatedFacets({
			newlyCreatedFacet: {
				hullCoords: facetPoints,
				konvaPolygonID: lineRef.current.id().split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0],
				isGroundMount: groundMountEnabled,
			}
		}));
	}

	function makeOtherPolygonsInvisbleWhileUserIsDrawing(opacity: number) {
		groupRef.current?.children?.forEach(c => {
			if (c instanceof Konva.Image) return;
			c.opacity(opacity);
		});
	}

	function makePolygon() {
		if (!konvaRef?.current) return;
		const { x, y } = konvaRef.current.getRelativePointerPosition() as Vector2d;

		// it only has img
		if (groupRef.current?.children?.length === 1) {
			pointsRef.current = [];
			isDrawing.current = false;
			const uuid = crypto.randomUUID();
			userFacetGroup.current = new Konva.Group({
				id: uuid + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME
			});
			pointLineRef.current = new Konva.Line(lineConfig);
			lineRef.current?.id(uuid + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.LINE_ID_SUFFIX);
		}
		// 
		if (pointsRef.current.length === 0 && !isDrawing.current) {
			// makeOtherPolygonsInvisbleWhileUserIsDrawing(0.2);
			groupRef.current?.add(userFacetGroup.current as Konva.Group);
			userFacetGroup.current?.add(lineRef.current);
			userFacetGroup.current?.add(pointLineRef.current);
			// groupRef.current?.add(lineRef.current);
			// groupRef.current?.add(pointLineRef.current);
		}
		if (pointsRef.current.length > 2 && distance([x, y], pointsRef.current[0]) < 10) {
			lineRef.current.closed(true);
			pointLineRef.current.remove();
			const pts = lineRef.current?.points();

			const p: number[][] = [];
			pts.forEach((v, i) => {
				const ind = Math.floor(i / 2);
				if (i % 2 === 0) p.push([v]);
				else p[ind].push(v);
			});
			console.log('ppoooooo', lineRef.current._id,);
			makeOtherPolygonsInvisbleWhileUserIsDrawing(1);
			dispatchNewlyCreatedFacets(p);
			if(props.showAzimuthArrows) {
				DrawArrows({
					polPoints: p,
					center: calculateCentroidOfPolygon(pts),
					userFacetGroup: userFacetGroup.current!,
					konvaRef,
					dispatchCallback: (id, azimuth) => {
						console.log('yyyyyo', id, azimuth);
						dispatch(setAzimuthOrPitchForNewlyCreatedNrelFacets({
							facetId: id,
							azimuthDegrees: azimuth,
						}));
					}
				});
				// dispatch(toggleManualToolModalState({value: true}));
			}
			dispatch(setUnfinalizedFacet({id: lineRef.current.id().split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0]}));
			// setInitialAzimuthForSegment(p, lineRef.current._id);
			pointsRef.current = [];
			const id = crypto.randomUUID();
			lineRef.current = new Konva.Line(
				{
					...lineConfig, id: id + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.LINE_ID_SUFFIX
				});
			pointLineRef.current = new Konva.Line(lineConfig);
			userFacetGroup.current = new Konva.Group({
				id: id + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME
			});
			isDrawing.current = false;
			return;
		}

		pointsRef.current.push([+x, +y]);
		addPoint(x, y);
		lineRef.current.points(pointsRef.current.flat());
		lineRef.current.on('contextmenu', handlePolygonClick);
		lineRef.current.on('touchstart', handleTouchStart);
		lineRef.current.on('touchend', handleTouchEndOrCancel);
		refDispatch({ type: 'ADD_LAST_POLYGON_REF', payload: { ref: {
			groupRef: userFacetGroup,
			pointsRef,
			lineRef,
			pointLineRef,
		}} });
	}

	function addPoint(x: number, y: number) {
		userFacetGroup.current?.add(new Circle({
			x: x,
			y: y,
			radius: 3,
			fill: 'blue',
			stroke: 'black',
			strokeWidth: 1
		}));
		isDrawing.current = true;
	}

	function handleMouseMove() {
		if (!editModeEnabled || !drawModeEnabled || !konvaRef?.current || !pointsRef.current.length) return;
		const { x, y } = konvaRef.current.getRelativePointerPosition() as Vector2d;
		const points = pointsRef.current;
		const [startX, starty] = points[points.length - 1];
		pointLineRef.current.points([startX, starty, x, y]);
	}

	function handlePolygonClick(e: KonvaEventObject<MouseEvent>) {
		if (!editModeEnabled || !drawModeEnabled) return;
		e.evt.preventDefault();
		const lineId = e.target.getAttr('id');
		console.log('target id', lineId);
		dispatch(setUnfinalizedFacet({ id: lineId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0] }));
		dispatch(setDropdownState({ open: true, position: { x: e.evt.clientX, y: e.evt.clientY } }));
	}

	function handleTouchStart(e: KonvaEventObject<TouchEvent>) {
		console.log('ddd', editModeEnabled, drawModeEnabled);
		if (!editModeEnabled || !drawModeEnabled) return;
		clearTimeout(longTouchTimerRef.current);
		longTouchTimerRef.current = setTimeout(() => {
			const lineId = e.target.getAttr('id');
			console.log('target id', lineId);
			dispatch(setUnfinalizedFacet({ id: lineId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0] }));
			dispatch(setDropdownState({ open: true, position: { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY } }));
		}, 2000);
	}

	function handleTouchEndOrCancel() {
		clearTimeout(longTouchTimerRef.current);
	}
	const totalCreateFacets = Object.keys(newlyCreatedFacets);

	return (

		<Group
			ref={groupRef}
			name={'panel-drawer'}
			onClick={(e) => {

				if (!canDrawFacet) return;
				if (mode !== DESIGN_MODES.DEFAULT && totalCreateFacets.length >= 1) {
					return;
				}
				if (e.evt.button === 0) {
					makePolygon();
				}
			}}
			onMouseMove={handleMouseMove}
		>
			<RasterImage />
		</Group>
	);
}

