import { memo, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { Group, Transformer } from 'react-konva';
import Konva from 'konva';
import { setRoofIndexes, setSelectedRoofIndex } from 'store/slices/ToolSlice';
import { useRefs } from 'contexts/RefContext';
import {
	AZIMUTH_CONSTRAINT, SEMI_TRANPARENT_BLACK_PANEL, FULL_TRANPARENT_BLACK_PANEL,
	KONVA_FACET_GROUP
} from '../../../constants';
import { disableDraggingInKonvaGroups, resetTransformations } from '../util';
import { KonvaEventObject } from 'konva/lib/Node';
import { setDropdownState } from 'store/slices/EditFacetModalSlice';
import Facets from './Facets';
import HullCoords from './HullCoords';
import PanelsWithHull from './PanelsWithHull';

function PanelsGroup(): JSX.Element {

	const { rasterSolarPanels: solarPanels, panel, allRoofSegs, } = useSelector((state: RootState) => state.roofData.data);
	const { 
		roofIndexes, editModeEnabled, 
		selectedRoofIndex, deletedRoofIndexes, 
		drawModeEnabled, moveStage
	} = useSelector((state: RootState) => state.toolNewPostions.data);
	const { activePanelMode, enableAddPanel, enablePanelMove, deleteIndividualPanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const dispatch = useDispatch<AppDispatch>();
	const canPerformEditMoveActions = editModeEnabled && !moveStage && !drawModeEnabled;

	const groupRefs = useRef<Konva.Group[] | null>([]);
	const trRef = useRef<Konva.Transformer | null>(null);
	const transformTimerRef = useRef<{
		timeElapsed: number,
		timeOut?: NodeJS.Timeout
	}>({
		timeElapsed: 0
	});
	const longTouchTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);

	const { konvaRef } = useRefs();
	const isIndividualPanelOperation= enableAddPanel || deleteIndividualPanelMode|| enablePanelMove;
	
	const solarPanelsGroupedByRoofIndex = useMemo(() => {
		groupRefs?.current && disableDraggingInKonvaGroups(groupRefs.current);
		groupRefs?.current && resetTransformations(groupRefs.current);
		groupRefs.current = [];
		trRef.current?.setNodes([]);
		
		const allRoofSegsObj: { [key: string]: RasterRoofSegment } = {};
		allRoofSegs.forEach(roofSeg => {
			allRoofSegsObj[roofSeg.id] = roofSeg;
		});
		const panelsToSelect = solarPanels;

		const obj: { [key: string]: Array<RasterSolarPanel & { userMapped:boolean }>} = {};
		for (let i = 0; i < panelsToSelect.length; i++) {
			const roofIndex = panelsToSelect[i].segmentId;
			const panel= {
				...panelsToSelect[i],
				userMapped: !!allRoofSegsObj[roofIndex]?.userMapped
			};
			if (!obj[roofIndex]) obj[roofIndex] = [panel];
			else obj[roofIndex].push(panel);
		}
		return obj;
	}, [solarPanels, allRoofSegs]);

	if (drawModeEnabled) {
		unselectFacet();
	}

	function addRoofIndex() {
		const roofIndex = trRef.current?.getNode()?.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0];

		// todo check hanle this in reducer itlsef
		if (!roofIndexes.includes(roofIndex))
			dispatch(setRoofIndexes({ roofIndexes: [...roofIndexes, roofIndex] }));
	}

	function makeFacetSelectable(id: string) {
		const roofIndex = id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0];
		console.log('roofIndexselect', roofIndex, id);
		if (!roofIndex) return;
		if (roofIndex === selectedRoofIndex) return;
		dispatch(setSelectedRoofIndex({ selectedRoofIndex: roofIndex }));
	}

	const [minRotation, maxRotation] = useMemo(() => {
		const rs = allRoofSegs.find(r => r.id === selectedRoofIndex);
		const initialRotation = rs?.azimuthDegrees || 0;
		const normalizeAngle = (angle: number) => {
			return ((angle + 180) % 360) - 180;
		};

		const minRotation = normalizeAngle(initialRotation - AZIMUTH_CONSTRAINT);//anticlock
		const maxRotation = normalizeAngle(initialRotation + AZIMUTH_CONSTRAINT);//clock
    
		return [minRotation, maxRotation];
	}, [allRoofSegs, selectedRoofIndex]);

	const constraintHandleRotation = useCallback((() => {
		let previousAngle = trRef.current?.getAbsoluteRotation();
		let groupId = trRef.current?.getNode()?.attrs?.id;
		return function () {
			const currentGroupId = trRef.current?.getNode()?.attrs?.id;

			if (!previousAngle || currentGroupId !== groupId) {
				previousAngle = trRef.current?.getAbsoluteRotation();
				groupId = currentGroupId;
				return;
			}

			const newAngle = trRef.current?.getAbsoluteRotation();
			if (!newAngle) return;

			const rs = allRoofSegs.find(r => r.id === currentGroupId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0]);
			const isInNegativeQuadrant = Math.abs(rs?.azimuthDegrees||0) > 90;
			if (isInNegativeQuadrant) {
				const normalizedNewAngle = ((newAngle + 360) % 360);
				const normalizedPreviousAngle = ((previousAngle + 360) % 360);
				const normalizedMaxRotaionAngle = ((maxRotation + 360) % 360);
				const normalizedMinRotaionAngle = ((minRotation + 360) % 360);

				const userIsRotatingFromLeftToRight = normalizedNewAngle > normalizedPreviousAngle && normalizedNewAngle < normalizedMaxRotaionAngle;
				const userIsRotatingFromRightToLeft = normalizedNewAngle < normalizedPreviousAngle && normalizedNewAngle > normalizedMinRotaionAngle;

				if (userIsRotatingFromLeftToRight || userIsRotatingFromRightToLeft) {
					previousAngle = newAngle;
					return;
				}

				if (normalizedNewAngle > normalizedMaxRotaionAngle || normalizedNewAngle < normalizedMinRotaionAngle) {
					trRef.current?.stopTransform();
					previousAngle = newAngle;
					return;
				}
			} else {
				const userIsRotatingFromLeftToRight = newAngle > previousAngle && newAngle < maxRotation;
				const userIsRotatingFromRightToLeft = newAngle > minRotation && newAngle < previousAngle;

				if (userIsRotatingFromLeftToRight || userIsRotatingFromRightToLeft) {
					previousAngle = newAngle;
					return;
				}

				if (newAngle > maxRotation || newAngle < minRotation) {
					trRef.current?.stopTransform();
					previousAngle = newAngle;
					return;
				}
			}
		};
	})(), [maxRotation, minRotation]);

	function unselectFacet() {
		if (!trRef.current?._nodes) return;
		if (trRef.current?._nodes[0]?.attrs.id) {
			const id = trRef.current._nodes[0].attrs.id;
			changePanelAlphanes(id, 'LEAVE');
			trRef.current.getNode()?.draggable(false);
			trRef.current.detach();
			makeFacetSelectable(id);
		}
	}

	function makeFacetTranslative(id: string) {
		if(isIndividualPanelOperation) {
			trRef.current?.setNodes([]);
			return;
		}
		// trRef.current?.getNode()?.draggable(false);
		trRef.current?.detach();
		const clickedGroup = konvaRef?.current?.findOne('#' + id) as Konva.Group | undefined;
		const rs = allRoofSegs.find(r => r.id === id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0]);
		if(!rs || !clickedGroup) return;
		
		clickedGroup?.draggable(true);
		if (!clickedGroup.rotation()) {
			clickedGroup?.rotation(rs.azimuthDegrees);
			clickedGroup?.getChildren().forEach(e => e.rotation(-rs.azimuthDegrees));
		}

		trRef.current?.setNodes([clickedGroup]);
	}

	const handleGroupClick = useCallback((() => {
		let previousSelectedGroupId: string;
		return function (id: string) {
			console.log('id', id);
			if (!previousSelectedGroupId) {
				previousSelectedGroupId = id;
				changePanelAlphanes(id, 'ENTER');
				makeFacetTranslative(id);
				makeFacetSelectable(id);
				return;
			}

			if (previousSelectedGroupId !== id) {
				changePanelAlphanes(previousSelectedGroupId, 'LEAVE');
				changePanelAlphanes(id, 'ENTER');
				makeFacetTranslative(id);
				makeFacetSelectable(id);
				previousSelectedGroupId = id;
				return;
			}

			if (previousSelectedGroupId === id) {
				changePanelAlphanes(id, 'ENTER');
				makeFacetTranslative(id);
				makeFacetSelectable(id);
			}
		};
	})(), [editModeEnabled, allRoofSegs, isIndividualPanelOperation, konvaRef]);

	function changePanelAlphanes(id: string, action: 'ENTER' | 'LEAVE') {
		const panelObjs = konvaRef?.current?.findOne('#' + id) as Konva.Group | undefined;
		if ( !panelObjs || !panelObjs?.children?.length) return;
		const lines = panelObjs?.children as Konva.Line[];
		for (let i = 1; i < lines.length; i++) {
			lines[i].attrs['fill'] = action === 'ENTER' ? FULL_TRANPARENT_BLACK_PANEL : SEMI_TRANPARENT_BLACK_PANEL;
		}
	}

	function handleTouchStart(e: KonvaEventObject<TouchEvent>) {
		if (!editModeEnabled) return;
		clearTimeout(longTouchTimerRef.current);
		longTouchTimerRef.current = setTimeout(() => {
			openEditFacetPopup({ x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY });
		}, 2000);
	}

	function handleTouchEndOrCancel() {
		clearTimeout(longTouchTimerRef.current);
	}

	function handleContextMenuEvt(e: KonvaEventObject<MouseEvent>, name: string) {
		if (!canPerformEditMoveActions || isIndividualPanelOperation) return;
		e.evt.preventDefault();
		console.log({ x: e.evt.clientX, y: e.evt.clientY }, 'pos');
		handleGroupClick(name);
		openEditFacetPopup({ x: e.evt.clientX, y: e.evt.clientY });
	}

	function openEditFacetPopup(position: Vector2d) {
		dispatch(setDropdownState({ open: true, position }));
	}

	function handleDragStart(id: string){
		if (!canPerformEditMoveActions || isIndividualPanelOperation) return;
		if (konvaRef?.current) {
			handleGroupClick(id);
			clearTimeout(longTouchTimerRef.current);
			konvaRef.current.container().style.cursor = 'move';
		}
	}

	return (
		<>
			{
				Object.entries(solarPanelsGroupedByRoofIndex).map((ob) => {
					if (deletedRoofIndexes.includes(ob[0])) return null;
					const groupId = ob[0] + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.EXISTING_GROUP_NAME;
					return (
						<Group
							key={ob[0] + panel.key}
							id={groupId}
							ref={(el) => {
								if (el && !groupRefs.current?.includes(el)) {
									groupRefs.current?.push(el);
								}
							}}
							name={KONVA_FACET_GROUP.EXISTING_GROUP_NAME}
							onContextMenu={(e)=>handleContextMenuEvt(e, groupId) }
							onClick={(e) => {
								if (!canPerformEditMoveActions) return;
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'move';
								handleGroupClick(groupId);
							}}
							draggable={editModeEnabled && !drawModeEnabled && !moveStage && !isIndividualPanelOperation}
							onTap={() => {
								if (!canPerformEditMoveActions) return;
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'move';
								handleGroupClick(groupId);
							}}
							onTouchStart={handleTouchStart}
							onTouchEnd={handleTouchEndOrCancel}
							onMouseLeave={() => {
								if (!canPerformEditMoveActions) return;
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
							onDragStart={() => handleDragStart(groupId)}
							onDragEnd={() => {
								if (!canPerformEditMoveActions || activePanelMode) return;
								addRoofIndex();
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
						>
						
							<PanelsWithHull panelsObj={ob}/>
						</Group>
					);
				})
			}
			{
				(groupRefs.current) &&
				<Transformer
					ref={trRef}
					anchorSize={20}
					resizeEnabled={false}
					borderEnabled={true}
					rotateAnchorOffset={25}
					borderStroke={'rgba(255, 147, 43, 0.80)'}
					anchorStroke={'rgba(255, 147, 43, 0.80)'}
					anchorFill={'rgba(255, 147, 43, 0.80)'}
					onTranformStart={() => {
						transformTimerRef.current.timeElapsed = performance.now();
						clearTimeout(transformTimerRef.current.timeOut);
					}}
					onTransform={constraintHandleRotation}
					onTransformEnd={() => {
						addRoofIndex();
						transformTimerRef.current.timeElapsed = performance.now() - transformTimerRef.current.timeElapsed;
						queueMicrotask(() => {
							transformTimerRef.current.timeOut = setTimeout(() => {
								transformTimerRef.current.timeElapsed = 0;
							}, 200);
						});
					}}
				/>
			}

			{
				solarPanelsGroupedByRoofIndex &&
				<Facets/>
			}
			{
				<HullCoords/>
			}
		</>
	);
}
export default memo(PanelsGroup);