import { AxiosError } from 'axios';
import { ApiErrorResponse, ErrorResponse } from './types';

export function errorResponseHandler(error: AxiosError) {
	const { response } = error as AxiosError<ErrorResponse>;
	if (response) {
		// client received an error response (5xx, 4xx)
		// making the error status code as standard Error Status Code
		const statusCode = response.status as number;

		if (statusCode >= 400 && statusCode < 500) {
			const { data } = response;

			if (statusCode === 404) {
				return {
					code: statusCode,
					error: 'E_NOT_FOUND',
					message: 'route not found'
				};
			}

			const { message, error = 'E_BAD_REQUEST' } = data;

			let errorMessage = message;

			if (typeof message === 'string') {
				errorMessage = { message, error };
			} else if (Array.isArray(message)) {
				const msg = message.find(e => e.error);
				errorMessage = msg || message[0];
			}

			return {
				code: statusCode,
				...(errorMessage as ApiErrorResponse)
			};
		}

		return {
			code: 500,
			error: 'E_S_W',
			message: 'Something went wrong',
		};
	}

	return {
		code: 500,
		error: 'E_S_W',
		message: 'Something went wrong',
	};

}
