import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { switchDesignMode } from 'components/ModeSelector/api'; // Adjust import paths
import { API_ERROR_CODES } from '../constants';
import { errorResponseHandler } from 'store/api/AxiosError';

type SwitchDesignModeVariables = {
  uuid: string;
  panelKey: string;
  mode: string;
};

export const useSwitchDesignModesQuery = (): UseMutationResult<
  void,
  AxiosError,
  SwitchDesignModeVariables
> => {
	return useMutation<void, AxiosError, SwitchDesignModeVariables>(
		async (variables) => {
			await switchDesignMode(variables);
		},
		{
			retry: (failureCount, error) => {
				const { error: parsedErrorMsg } = errorResponseHandler(error);
				console.log('error', error, parsedErrorMsg);
				return (
					parsedErrorMsg !== API_ERROR_CODES.INVALID_MODE_CHECKOUT &&
          failureCount < 2
				);
				
			},
			mutationKey: ['switch-design-mode',]
		}
	);
};

export default useSwitchDesignModesQuery;

