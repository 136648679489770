import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { QueryParamsFromParentApp } from './types';
import { getInitialState } from './util/getInitialState';
import { recalcuatedEnergyVals } from '../RoofDataSlice/roofDataSlice';

const initialState: { data: QueryParamsFromParentApp } = {
	data: getInitialState()
};

export const QueryParamsFromParentAppSlice = createSlice({
	name: 'queryParamsProvider',
	initialState,
	reducers: {
		setQueryParamState: (state, action: PayloadAction<QueryParamsFromParentApp>) => {
			state.data = action.payload;
		},
		setEnergyDerate: (state, action: PayloadAction<{ energyDerate: number,  }>) => {
			state.data.derateRateBeforeUserSwitchToNonDefaultModes = state.data.energyDerateRate;
			state.data.energyDerateRate = action.payload.energyDerate;
		},
		setEneryDerateToQueryParamState: (state, action: PayloadAction) => {
			state.data.energyDerateRate = state.data.derateRateBeforeUserSwitchToNonDefaultModes;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(recalcuatedEnergyVals.fulfilled, (state, action) => {
				if (state.data.electricUnitPrice !== action.payload.energyState.electricUnitPrice)
					state.data.electricUnitPrice = action.payload.energyState.electricUnitPrice;
			});
	}
});

export const { setQueryParamState, setEnergyDerate, setEneryDerateToQueryParamState } = QueryParamsFromParentAppSlice.actions;

export default QueryParamsFromParentAppSlice.reducer;