import styled, { Interpolation } from 'styled-components';
type props = {
	styles?: Interpolation<React.CSSProperties>
	fullScreenEnabled?:boolean
}

export const StageWrap = styled.div` 
	height:100%;
	position:relative;
	overflow: hidden;
	// inorder to set the height here try adjusting the scale first
	// in index.tsx tool
	// height: 300px;
	// width: 800px;

	.production-indicator {
		width: 40%;
    position: absolute;
    z-index: 1;
    left: 1rem;
    top: 1rem;
	}
	
	@media (max-width: 500px){
		.production-indicator {
			width: 89%;
		}
	}
	@media only screen and (min-width:1200px){
		// height:100%;
	}
	.konvajs-content{
		// max-height: 300px;
		@media only screen and (min-width:1200px){
			// width:100%!important;
			// height:100%!important;
			// max-height:100%;
		}
		canvas{
			// max-height:100%;
			object-fit:cover;	
			object-position:center;
			// @media only screen and (min-width:1200px){
			// 	width:100%!important;
			// 	height:100%!important;
			// }
		}
	}
	> div{
		height:100%;
		position: relative;
		// @media (max-width: 500px){
		// 	right: 28%;
		// }
		// @media (max-width: 400px){
		// 	right: 36%;
		// }
		// @media (max-width: 375px){
		// 	right: 38%;
		// }
	}
@media (max-width: 935px){
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 640px){
	display: block;
	justify-content: center;
	align-items: center;
}
	
`;

export const FlexDiv= styled.div`
background: url(bg3dsq.png)  repeat;
background-position: center;
position: relative;
@media (max-width: 935px){
	// width:100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	// background: rgba(243, 245, 249, 0.34);
}

`;

export const LoaderDiv = styled.div`
display: flex;
flex-direction:column;
width: 100%;
height: 100%;
align-items: center;
justify-content: center;
img{margin-top: 5px;};
position:absolute;
top:0;
background: #ffffff8f;

`;

export const StripProductionWrap= styled.div<props>`
	display: flex;
	position: ${(props) => (props.fullScreenEnabled ? 'fixed' : 'absolute')};
	align-items: end;
	justify-content: center;
	top: 0;
	pointer-events: none;
	bottom: 20px;
	left: calc(1058px - 60%);
	max-width: 240px;
    width: 100%;
		@media (max-width: 935px){
			position: absolute;
		}
`;
export const MoveZoomWrapper= styled.div<props & {showOnLeft: boolean}>`
position: ${(props) => (props.fullScreenEnabled ? 'fixed' : 'absolute')};
${({ showOnLeft }) => showOnLeft &&
	`	left: 20px;
		display: flex;
		align-items: flex-start;
		bottom: 60px;
	`
}
@media (max-width: 935px){
	position: absolute;
}
`;