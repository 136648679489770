import Button from 'components/Basic/Button';
import CustomTooltip from 'components/Basic/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setDeleteIndividualPanelsMode, togglePanelModal } from 'store/slices/PanelSlice';
import CancelDeletePanelModal from './CancelDeletePanelModal';

export default function DeletePanelBtn() {
	const dispatch = useDispatch();
	const { activePanelMode, deleteIndividualPanelMode, deletedPanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { drawModeEnabled, roofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);
	const shouldDisable = activePanelMode && !deleteIndividualPanelMode || drawModeEnabled || roofIndexes.length > 0;

	function handleClick() {
		if(deleteIndividualPanelMode && deletedPanels.length > 0){
			dispatch(togglePanelModal({targetModal: 'CANCEL_DELETE_PANEL', val:true}));
			return;
		}
	
		dispatch(setDeleteIndividualPanelsMode({ enabled: !deleteIndividualPanelMode }));
	}

	function handleCancelCLick(event: React.MouseEvent<HTMLButtonElement>){
		event.stopPropagation();
		if(deletedPanels.length > 0){
			dispatch(togglePanelModal({targetModal: 'CANCEL_DELETE_PANEL', val:true}));
			return;
		}

		dispatch(setDeleteIndividualPanelsMode({ enabled: false }));
	}

	return (
		<>
			<Button style={{
				background: `${deleteIndividualPanelMode ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
				border: '0px',
				borderRight: '0px',
				borderLeft: '0px',
				color: '#28373E',
				fontSize: '0.938rem',
				padding: '0px 8px',
				height: '36px',
				// width: `${!deleteIndividualPanelMode ? '40px' : '48px'}`,
				minWidth: '40px',
				width: 'auto',
				borderRadius: '0px',
				
			}}
			id="delete-panel"
			disabled={shouldDisable}
			onClick={handleClick}
			// title="Delete Panel"
			>
				{/* <svg width="17" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7 17H1V1H13V9.5" stroke={`${deleteIndividualPanelMode ? '#003CFF' : '#4A525A'}`} strokeWidth="1.6" strokeLinejoin="round" />
					<path d="M14.8278 17.8284L9.17097 12.1716M14.8278 12.1716L9.17097 17.8284" 
						stroke={`${deleteIndividualPanelMode ? '#003CFF' : '#4A525A'}`} strokeWidth="1.6" strokeLinejoin="round" />
				</svg> */}
				<svg width="16" height="18" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.69167 21.0315H1.3208V1.93506H15.6431V7.90269" 
						stroke={`${deleteIndividualPanelMode ? '#003CFF' : '#4A525A'}`} strokeWidth="1.90964" strokeLinejoin="round"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M13.7501 17.1643L17.793 21.2072L19.2072 19.793L15.1643 
					15.7501L19.2072 11.7072L17.793 10.293L13.7501 14.3359L9.70718 10.293L8.29297 11.7072L12.3359 15.7501L8.29297 
					19.793L9.70718 21.2072L13.7501 17.1643Z" fill={`${deleteIndividualPanelMode ? '#003CFF' : '#4A525A'}`}/>
				</svg>

				{deleteIndividualPanelMode && (
					<label className='buttonLabel'>
						Delete Panel
						<span className='buttonIcon' onClick={handleCancelCLick}>	
							<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5.71132 4.50043L8.73856 1.47233C8.82035 1.39334 8.88559 1.29884 8.93048 1.19436C8.97536 
								1.08988 8.99898 0.97751 8.99997 0.863803C9.00096 0.750096 8.97929 0.637331 8.93623 0.532087C8.89317 
								0.426843 8.82958 0.331229 8.74918 0.250823C8.66877 0.170416 8.57316 0.106829 8.46791 0.0637703C8.36267 
								0.0207117 8.2499 -0.000955751 8.1362 3.23334e-05C8.02249 0.00102042 7.91012 0.0246443 7.80564 0.0695253C7.70116 
								0.114406 7.60667 0.179646 7.52767 0.261437L4.49957 3.28868L1.47233 0.261437C1.39334 0.179646 1.29884 0.114406 
								1.19436 0.0695253C1.08988 0.0246443 0.97751 0.00102042 0.863803 3.23334e-05C0.750096 -0.000955751 0.637331 
								0.0207117 0.532087 0.0637703C0.426843 0.106829 0.331229 0.170416 0.250823 0.250823C0.170416 0.331229 0.106829 
								0.426843 0.0637703 0.532087C0.0207117 0.637331 -0.000955751 0.750096 3.23334e-05 0.863803C0.00102042 0.97751 
								0.0246443 1.08988 0.0695253 1.19436C0.114406 1.29884 0.179646 1.39334 0.261437 1.47233L3.28868 4.49957L0.261437 
								7.52767C0.179646 7.60667 0.114406 7.70116 0.0695253 7.80564C0.0246443 7.91012 0.00102042 8.02249 3.23334e-05 
								8.1362C-0.000955751 8.2499 0.0207117 8.36267 0.0637703 8.46791C0.106829 8.57316 0.170416 8.66877 0.250823 
								8.74918C0.331229 8.82958 0.426843 8.89317 0.532087 8.93623C0.637331 8.97929 0.750096 9.00096 0.863803 8.99997C0.97751 
								8.99898 1.08988 8.97536 1.19436 8.93048C1.29884 8.88559 1.39334 8.82035 1.47233 8.73856L4.49957 5.71132L7.52767 
								8.73856C7.60667 8.82035 7.70116 8.88559 7.80564 8.93048C7.91012 8.97536 8.02249 8.99898 8.1362 8.99997C8.2499 
								9.00096 8.36267 8.97929 8.46791 8.93623C8.57316 8.89317 8.66877 8.82958 8.74918 8.74918C8.82958 8.66877 8.89317 
								8.57316 8.93623 8.46791C8.97929 8.36267 9.00096 8.2499 8.99997 8.1362C8.99898 8.02249 8.97536 7.91012 8.93048 
								7.80564C8.88559 7.70116 8.82035 7.60667 8.73856 7.52767L5.71132 4.50043Z" fill="#4A525A" />
							</svg>
						</span>
					</label>
				)}
			</Button>
			<CustomTooltip
				anchorSelect={'#delete-panel'}
				content={'Delete Panel'}
			/>
			<CancelDeletePanelModal />
		</>
	);
}