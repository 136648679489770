import axios from 'axios';
import { getToken, createApiUrl } from '../../util';

export async function switchDesignMode(input: { mode: string, uuid: string, panelKey: string }) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	const apiUrl = '/designs/switch-design-mode/';
	const { data } = await axios.patch(createApiUrl(apiUrl), input, config);
	console.log('deea', data);
	return true;
}