import axios from 'axios';
import { getToken, createApiUrl } from '../../util';
import { NewlyCreatedFacetHullCoords } from 'store/slices/ToolSlice/types';

async function translatePanels(translatePos: any, panelKey: string, uuid: string) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	const body = {
		translatePositions:translatePos,
		uuid,
		panelKey
	};

	const apiUrl = '/designs/translate-panels-to-new-position';
	const { data } = await axios.post(createApiUrl(apiUrl), body, config);
	return data as RawSolarQueryData;
}

async function createNewFacets(newFacets: { newPanelFacets: NewlyCreatedFacetHullCoords[] , deletedRoofSegs: string[]}, 
	panelKey: string, uuid: string) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	const body = {
		panelFacets: newFacets.newPanelFacets,
		deletedRoofSegs: newFacets.deletedRoofSegs,
		uuid,
		panelKey,
	};

	const apiUrl = '/designs/edit-roof-segments';
	const { data } = await axios.post(createApiUrl(apiUrl), body, config);
	return data as RawSolarQueryData;
}

export {
	translatePanels,
	createNewFacets
};