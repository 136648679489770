import axios from 'axios';
import { createApiUrl, getToken, generateQueryParams } from '../../util';

type GetDesignReqQParams = {
	uuid: string;
	panelKey?: string;
	gutterSetback?: number;
	nonGutterSetback?: number;
	obstructionSetback?: number;
}

async function getDesignByExternalID(externalId: string, panelKey?: string) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};
	const apiUrl = `/designs/design-by-externalId${generateQueryParams({ externalId, panelKey: panelKey ? encodeURIComponent(panelKey as string) : undefined })}`;
	const { data } = await axios.get(createApiUrl(apiUrl), config);
	return data as RawSolarQueryData;
}

async function getDesignByUUID(qparamsObj: GetDesignReqQParams) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	qparamsObj.panelKey = qparamsObj.panelKey ? encodeURIComponent(qparamsObj.panelKey): undefined;

	const apiUrl = `/designs/design-by-uuid${	generateQueryParams({...removeUndefinedKeys(qparamsObj)})}`;
	const { data } = await axios.get(createApiUrl(apiUrl), config);
	return data as RawSolarQueryData;
}

function removeUndefinedKeys<T extends Record<string, any>>(payload: T): T {
	return Object.fromEntries(
		Object.entries(payload).filter(([_, value]) => value !== undefined)
	) as T;
}

export {
	getDesignByExternalID,
	getDesignByUUID, 
};
