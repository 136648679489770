import { Image } from 'react-konva';
import useImage from 'use-image';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setMouseState } from 'store/slices/ToolSlice';
import useRasterDims from 'hooks/useRasterDims';

export default function RasterImage() {

	const { jpgUrl } = useSelector((state: RootState) => state.roofData.data);
	const dispatch = useDispatch<AppDispatch>();
	const [image] = useImage(jpgUrl, 'anonymous', 'origin');
	const imgDims = useRasterDims();
	
	const handleMouseEnter = () => {
		dispatch(setMouseState({
			mouseOnMap: true
		}));
	};

	const handleMouseLeave = () => {
		dispatch(setMouseState({
			mouseOnMap: false
		}));
	};

	return (
		<Image
			image={image}
			preventDefault={false}
			height={imgDims.height}
			width={imgDims.width}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		/>
	);
}