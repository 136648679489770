import axios from 'axios';
import { getToken, createApiUrl, generateQueryParams } from '../../util';

export async function switchDesignImagery(input: { source: string, uuid: string, panelKey: string }) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	const apiUrl = '/designs/switch-design-source/';
	const { data } = await axios.patch(createApiUrl(apiUrl), input, config);
	console.log('deea', data);
	return true;
}