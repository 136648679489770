import Button from 'components/Basic/Button';
import Konva from 'konva';
import { useRefs } from 'contexts/RefContext';
import { useEffect, useState } from 'react';
import CustomTooltip from 'components/Basic/Tooltip';

export default function UndoButton() {

	const { lastPolygonRef } = useRefs();
	const { groupRef, pointsRef, lineRef, pointLineRef } = lastPolygonRef;
	const [undoEnabled, setUndoEnabled] = useState<boolean>(!!pointsRef?.current.length);
	console.log(lastPolygonRef);

	useEffect(() => {
		const checkUndoEnabled = () => {
			setUndoEnabled(!!pointsRef?.current.length);
		};
		checkUndoEnabled();
	}, [pointsRef?.current.length]);

	function handleUndoClick() {
		if (!pointsRef?.current.length) return;

		const children = groupRef?.current?.children;
		pointLineRef?.current.points([]);

		if (children && children.length > 1) {
			const lastChild = children[children.length - 1];
			if (lastChild instanceof Konva.Image || lastChild instanceof Konva.Line) return;
			lastChild.destroy();
		}

		const points = lineRef?.current.points();
		if (points && points.length >= 2) {
			points?.splice(-2);
			lineRef?.current.points(points);
		}
		if (pointsRef.current.length > 0) {
			pointsRef.current.splice(-1);
		}
		console.log(pointsRef);
		setUndoEnabled(!!pointsRef?.current.length);
	}

	return (
		undoEnabled ?
			<>
				<Button
					className={undoEnabled ? 'active' : ''}
					id="undo"
					style={{
						background: `${undoEnabled ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
						border: '0px',
						borderLeft: '1px solid #E4E4E4',
						borderRight: '0px',
						color: `${undoEnabled ? '#003CFF' : '#28373E'}`,
						fontSize: '14px',
						padding: '0px 10px 0 8px',
						height: '36px',
						minWidth: '40px',
						borderRadius: '0px',
						transform: 'scale(-1,1)'
					}}
					onClick={handleUndoClick}
					tooltipContent='Undo'>
					{/* <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M4 7H15C16.8692 7 17.8039 7 18.5 7.40193C18.9561 7.66523 19.3348 8.04394 
					19.5981 8.49999C20 9.19615 20 10.1308 20 12C20 13.8692 20 14.8038 19.5981 15.5C19.3348 
					15.9561 18.9561 16.3348 18.5 16.5981C17.8039 17 16.8692 17 15 17H8.00001M4 7L7 4M4 7L7 10" 
					stroke={`${undoEnabled ? '#003CFF' : '#28373E'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				</svg> */}
					<svg width="14" height="16" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19.6296 17.3987C17.8772 20.2562 14.7634 22.1569 11.2142 22.1569C5.73672 22.1569 
					1.29639 17.63 1.29639 12.0457C1.29639 6.46149 5.73672 1.93457 11.2142 1.93457C15.2358 
					1.93457 18.6983 4.3749 20.2548 7.88231M20.2548 7.88231V2.52934M20.2548 7.88231H15.2979"
						stroke={`${undoEnabled ? '#003CFF' : '#28373E'}`}
						strokeWidth="1.90964"
						strokeLinecap="round"
						strokeLinejoin="round" />
					</svg>

				</Button>
				<CustomTooltip
					anchorSelect={'#undo'}
					content={'Undo'}
				/>
			</>
			:
			null
	);

}