import { getAbsolutePoints } from 'components/DisplayEnergy/util';
import Konva from 'konva';
import { normalizeAngle } from '../util';
import { KONVA_FACET_GROUP } from '../../../constants';

export function getAbsolutePointsOfTranslatedPanels(stage: Konva.Stage, roofIndexes: string[], 
	allRoofSegs: RasterRoofSegment[], currentScale: Vector2d
) {
	try {
		const groups = stage.find( '.' + KONVA_FACET_GROUP.EXISTING_GROUP_NAME)
			.filter(g => roofIndexes.includes(g.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0])) as Konva.Group[];
		const newPos: {
		segmentId: number,
		azimuth: number,
		previousAzimuthDegrees: number,
		translatedPos: {
			[id: string]: {
				pos: number[][]
			}
		};
		pitch?: number;
		shading?: number;
		prevShading?: number;
		previousPitchDegrees?: number;
	}[] = [];

		//TODO: key in payload for transformed hull coords

		const stagePosition= stage.position();
		groups?.forEach((g,i) => {
			const translatedPos: typeof newPos[0]['translatedPos'] = {};
			let roofSegmentTranslatedPos:number[][]= []; // tranformed hull coords
			const segmentId = g.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0];
			console.log(g.children, g);
			g.children?.forEach((child) => {
				
				if (child.className === 'Line' ) {
					const newPoints = (getAbsolutePoints(child as Konva.Line, currentScale, stagePosition) as number[]);
					if(child.attrs.id === segmentId){
						// traslated pos for roof segments
						roofSegmentTranslatedPos= transformFlatArrayToCoordinateTypeArray(newPoints);
					}else{
						//translated postion for panels 
						translatedPos[child.attrs.id] = {
							pos: [...transformFlatArrayToCoordinateTypeArray(newPoints)]
						};
					}
					
				}
			});
			const roofSegment = allRoofSegs.find(rs => rs.id === segmentId);
			// todo push like previously or continue like this
			const newPitch = g.getAttr('pitch');
			const shading = g.getAttr('shading');
			if(!roofSegment) return [];
			newPos.push({
				segmentId,
				azimuth: normalizeAngle( g.rotation()-roofSegment.azimuthDegrees) ,
				previousAzimuthDegrees: roofSegment.azimuthDegrees,
				translatedPos,
				previousPitchDegrees: roofSegment.pitchDegrees,
				pitch: newPitch ?? roofSegment.pitchDegrees,
				shading: shading ?? roofSegment.shading,
				prevShading: roofSegment.shading,
			});

		});
		return newPos;
	}catch(e){
		console.log('errr', e);
		throw new Error('E_WHILE_GETTING_TRANSLATION_FOR_SEGMENT');
	}
}

export function resetTraslateFacets(stage: Konva.Stage, roofIndexes: string[]) {
	const groups = stage.find( '.' + KONVA_FACET_GROUP.EXISTING_GROUP_NAME)
		.filter(g => roofIndexes.includes(g.attrs.name.split(KONVA_FACET_GROUP.EXISTING_GROUP_NAME)[0])) as Konva.Group[];

	groups?.forEach(g => {
		g.setPosition({ x: 0, y: 0 });
	});
}

export function transformFlatArrayToCoordinateTypeArray(list: number[]): number[][] {
	const transformedArray = [];
	for (let i = 0; i <= list.length - 2; i += 2) {
		transformedArray.push([list[i], list[i + 1]]);
	}
	return transformedArray;
}

