import { FlexDivWrap, ToolbarLabel } from './styles';
import Konva from 'konva';
import { suggestNewPanelAlignment } from 'store/slices/RoofDataSlice/roofDataSlice';
import { negateEnergyProductionOfPanelsWhichAreStillInEditMode } from 'store/slices/EnergySlice/energySlice';
import { setEditState } from 'store/slices/ToolSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { useRefs } from 'contexts/RefContext';
import { useRef } from 'react';
import { disableDraggingInKonvaGroups, deleteAllFacetsFromPanelDrawer } from 'components/tool/util';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { KONVA_FACET_GROUP, SEMI_TRANPARENT_BLACK_PANEL } from '../../../../constants';
import { EnergyState } from 'store/slices/EnergySlice/types';

export function EditButtonDiv() {

	const {
		roofIndexes, editModeEnabled, threeDModelEnabled, userHasModifiedRoofSegs
	} = useSelector((state: RootState) => state.toolNewPostions.data);
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { roofSegsFromWhichUserHasSelectedPanels } = useSelector((state: RootState) => state.roofData.data);
	const { annualInputEnergy } = useSelector((state: RootState) => state.energyData.data as EnergyState);
	const dispatch = useDispatch<AppDispatch>();
	const { konvaRef } = useRefs();
	const toggleRef = useRef<HTMLInputElement>(null);
	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const someFacetIsStillInEditMode = roofSegsFromWhichUserHasSelectedPanels.filter(ind => roofIndexes.includes(ind));
	const diableEditToggle = queriesRunningInBackGround || threeDModelEnabled || showIrradiance || !!someFacetIsStillInEditMode?.length || activePanelMode;

	function restorePanelColors() {
		if (!konvaRef?.current) return;
		(konvaRef?.current.find( '.' + KONVA_FACET_GROUP.EXISTING_GROUP_NAME) as unknown as Konva.Group[] | undefined)?.forEach((g => {
			if (!roofIndexes.includes(g.attrs['id'].split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0])) return;
			const lines = g?.children as Konva.Line[];
			if (lines.length > 0 && lines[0].attrs['fill'] === SEMI_TRANPARENT_BLACK_PANEL) return;
			for (let i = 0; i < lines.length; i++) {
				lines[i].attrs['fill'] = SEMI_TRANPARENT_BLACK_PANEL;
			}
		}));
	}

	return (
		<FlexDivWrap>
			<div className='switchButton bg-transparent'>
				<ToolbarLabel className='toolbar-label'>Edit</ToolbarLabel>
     
				<div className="toggle-button-cover">
					<div className="button-cover">
						<div className="button b2" id="button-13">
							<input
								checked={editModeEnabled}
								ref={toggleRef}
								onChange={async () => {
									if (toggleRef.current) {
										const editEnabled = toggleRef.current.checked;
										toggleRef.current.checked = editEnabled;
										dispatch(setEditState({ enabled: editEnabled }));

										if (!editEnabled && konvaRef?.current) {
											(konvaRef.current.findOne('Transformer') as Konva.Transformer).setNodes([]);
											if (userHasModifiedRoofSegs) {
												await dispatch(suggestNewPanelAlignment({ energy: annualInputEnergy }));
											}
											disableDraggingInKonvaGroups(konvaRef?.current.find( '.' + KONVA_FACET_GROUP.EXISTING_GROUP_NAME));
											dispatch(negateEnergyProductionOfPanelsWhichAreStillInEditMode(roofIndexes));
											restorePanelColors();
											deleteAllFacetsFromPanelDrawer(konvaRef.current);
											// dispatch(recalcuatedEnergyVals({ energy: annualInputEnergy }));
										}
									}
								}}
								type="checkbox"
								className="checkbox"
								disabled={diableEditToggle}
							/>
							<div className="knobs">
								<span></span>
							</div>
							<div className="layer"></div>
						</div>
					</div>
				</div>
			</div>
		</FlexDivWrap>);

}