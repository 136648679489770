import styled, { Interpolation } from 'styled-components';

type props = {
	styles?: Interpolation<React.CSSProperties>
	isParentSQ?: boolean;
	fullScreenEnabled?:boolean
}

export const AppBoxWrap = styled.div<props>`
	background: transparent;
	padding: 0rem;
	min-height: 100%;
  height: 100%;
	.design-Container{
		margin:auto;
		height: 100%;
		gap:0.5rem;
		overflow-y: auto;
		grid-template-rows:${(props) => (props.fullScreenEnabled ? 'auto' : 'max-content auto')} ;
	}
	.design-body{
		height:100%;
		overflow:auto;
	}
	.design-head{
		display:flex;
		justify-content:space-between;
		align-items:center;
		column-gap:1rem;
		flex-wrap:wrap;
		padding:0 1rem;
		position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
	}
.address{
	font-size: 0.85rem;
	color: rgb(78, 78, 78);
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	gap: 0.3rem;
	margin-top: 0.5em;
	svg{
		stroke: rgb(78, 78, 78);
    width: 1em;
	}
}
		h3{
			margin:0;
		}
		.reset-btn{
			border:1px solid #DBDDE2;
			background:#fff;
			color:#4A525A;
			font-weight:700;
			font-size: 0.9rem;
    	padding: 0.8em 1.4em;
			border-radius:0.3em;
		}
	}
	@media (max-width: 500px) {
    .talk-to-expert{
      padding: 0.6em;
      width: 100%;
      font-size: .875rem;
    }
	
    
  }
	@media (max-width: 980px) {
		.design-Container {
			width: 100%;
	}
	.design-body{
	width:100%
	}
	}
  @media (max-width: 935px) {
    .design-head{
      flex-direction:column;
      align-items: start;
    }
    .talk-to-expert{
      width: 100%;
    }
    .mobileview{
      display: flex;
			align-items: center;
			justify-content: space-between;
			width:100%;
			font-size: 1.325rem;

    }
  }

`;

export const EnergyDiv = styled.div<props>`
display: ${(props) => (props.fullScreenEnabled ? 'flex' : 'grid')};
flex-direction:${(props) => (props.fullScreenEnabled ? 'column' : 'column')};
height:${(props) => (props.fullScreenEnabled ? '100%' : 'auto')};
// overflow:hidden;
	grid-template-columns: 65% 35%;
	// margin-top:${(props) => (props.fullScreenEnabled ? '54px' : '0px')};
		@media (max-width: 935px) {
			width: 100%;
			margin: 0 auto;
			box-sizing: border-box;
			grid-template-rows: max-content auto;
			grid-template-columns: none;
			display:${(props) => (props.fullScreenEnabled ? 'grid' : 'flex')};
			flex-direction:${(props) => (props.isParentSQ ? 'column-reverse' : 'column')};
			
		}
		@media (max-width: 500px) {
			width: 100%;
			max-width:100%;
			box-sizing: border-box;
		}
		@media (max-width: 980px) {
			flex:1
		}
		
`;

export const ToolDiv = styled.div<props>`
	display: flex;
	flex-direction: column;
	grid-template-rows: max-content auto;
	gap:1rem;
		padding:${(props) => (props.isParentSQ ? '0.5rem 1rem' : props.fullScreenEnabled? '0.5rem 1rem': '0.25rem 0rem 0.5rem')} ;

    background: ${(props) => (props.fullScreenEnabled ? '#ECEFF4' : '#fff')};
		position: ${(props) => (props.fullScreenEnabled ? 'sticky' : 'static')};
		width:100%;
    top: 0;
    z-index: 99;
		// overflow:hidden;
    // box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
    border-radius: ${(props) => (props.fullScreenEnabled ? '0px' : '8px')};
		@media (max-width: 935px) {
			width: 100%;
			box-sizing: border-box;
			margin: 0 auto;
			position: ${(props) => (props.fullScreenEnabled ? 'sticky' : 'static')};
			// overflow:visible;
		}
		
}
`;

export const AppFlexBoxWrap = styled.div<props>`
	display:flex;
	grid-template-rows: max-content auto;
	flex-direction:column;
	padding: 0;
	margin: 0 auto;
	height:${(props) => (props.fullScreenEnabled ? '100%' : 'auto')};
	@media (max-width: 935px) {
		// grid-template-rows: max-content auto;
    // grid-template-columns: none;

		// display:flex;
		// flex-direction:${(props) => (props.isParentSQ ? 'column-reverse' : 'column')};
		// height:auto;
	}
	@media (max-width: 980px) {
		height:${(props) => (props.fullScreenEnabled ? '100%' : '100%')};
	}
`;

