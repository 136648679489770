import {
	RightSidePanel,
	PanelDetailDiv,
	SQMobileViewWrap,
	MobileViewWrap,
	DesktopViewWrap,
} from './style';
import { useQuery } from 'react-query';
import { updateDesing } from 'pages/LocationMap/api';
import {
	useEffect,
	useRef,
	useState,
	useCallback,
} from 'react';
import { getPanelDetails, getMonthlyDetails, deleteMonthlyDetails, getHourlyEnergy } from './api';
import { Id, toast } from 'react-toastify';
import { Design, MonthlyEnergyPerSegment, perPanelEnergyDetails } from './types';
import { getEnergyData, changeVisibilityOfPanelsAndGetCanvasImage, adjustScalingWhileSavingDesignImage, setZoomlevelOnSave } from './util';
import { BREAK_POINT_FOR_MOBILE_VIEW, DEFAULT_KONVA_STAGE_POSITION, DESIGN_MODES, PARENT_EVENTS, SOlAR_QOUTE_APP_NAME } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { useRefs } from 'contexts/RefContext';
import AllPanelsToggle from './AllPanelsToggle';
// import SideModal from 'components/ManualToolModal';
import { setUserHasClickedSave, batchUpdateToolState } from 'store/slices/ToolSlice';
import { setIrrandiance } from 'store/slices/IrradianceSlice';
import NextSteps from './NextStep';
import useScreenDims from 'hooks/useScreenDims';
import {
	AdjustPanelColorToggle,
	AnnualUsage,
	ChangePanelSelector,
	ElectricBillInput,
	Offset,
	Production,
	SaveDesignBtn,
	SystemSize
} from './PanelComponent';
import DesignModesSelector from 'components/ModeSelector';
import RecommendPanelsButton from './PanelComponent/RecommendPanelsButton';
import SideModal from 'components/ManualToolModal';

interface CustomEventInit extends EventInit {
	design: Design;
	onlySync: boolean;
}

export default function DisplayEnergy() {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const energy = useSelector((state: RootState) => state.energyData.data as unknown as EnergyState);
	const { uuid, roofSegs, roofSegsFromWhichUserHasSelectedPanels, panel, org,
		selectedPanelKey, showAllPanels,latLng, mode, imagerySource } = useSelector((state: RootState) => state.roofData.data);
	const { parentAppName, energyDerateRate, stateCode } = useSelector((state: RootState) => state.QueryParams.data);
	const [error, setError] = useState<boolean>(false);
	// const [forOnlySync, setForOnlySync] = useState<boolean>(true);
	const { konvaRef } = useRefs();
	const previouseToastId = useRef<Id>();
	const offset = Math.round((energy.deratedEnergy / energy.billEnergy) * 100);
	const isParentSolarQuote = parentAppName === SOlAR_QOUTE_APP_NAME;
	const { roofIndexes, editModeEnabled, zoomLevel, fullScreenEnabled } =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const someFacetIsStillInEditMode = roofSegsFromWhichUserHasSelectedPanels.filter(ind => roofIndexes.includes(ind));
	const disableOnEdit = editModeEnabled || !!someFacetIsStillInEditMode.length;
	const dispatch = useDispatch<AppDispatch>();
	const { width: screenWidth } = useScreenDims();
	const showMobileView = screenWidth <= BREAK_POINT_FOR_MOBILE_VIEW;
	// console.log('isSyncedWithParent: ', isSyncedWithParent);

	const showSelectedPanelsErrorToast = useCallback(() => {
		if (previouseToastId?.current) return;
		previouseToastId.current = toast(
			'To move forward, please choose a minimum of three panels',
			{
				progressStyle: {
					background: 'var(--primary)',
				},
				onClose: () => {
					previouseToastId.current = undefined;
				},
			}
		);
	}, []);

	useEffect(() => {
		function handleSaveDesignEvent(event: MessageEvent) {
			if (event.data === PARENT_EVENTS.SAVE_DESIGN) {
				saveDesignQuery.refetch();
			}
		}
		window.addEventListener('message', handleSaveDesignEvent);
		return () => {
			window.removeEventListener('message', handleSaveDesignEvent);
		};
	});

	function fetchMonthlyDetails(uuid: string, panelKey: string): Promise<{ [key: string]: perPanelEnergyDetails }> {
		let retryCount = 0;
		let allPanelsMonthlyDetails: { [key: string]: perPanelEnergyDetails } = {};
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			try {
				while (retryCount <= 2) {
					const monthlyData = await getMonthlyDetails(uuid, panelKey);
					allPanelsMonthlyDetails = {};
					let panelMismatches = 0;
					monthlyData?.forEach((segment: MonthlyEnergyPerSegment) => {
						const { perPanelEnergyDetails } = segment;
						Object.values(perPanelEnergyDetails).forEach((panel) => {
							allPanelsMonthlyDetails[panel.panelId] = panel;
						});
					});
					energy.selectedPanels.forEach(panel => {
						if (!Object.keys(allPanelsMonthlyDetails).includes(panel)) panelMismatches++;
					});
					if (panelMismatches > 0) {
						console.log(`panel ids arent matching , going to delete the ${panelKey} key`);
						await deleteMonthlyDetails(uuid, panelKey);
						retryCount += 1;
						if (retryCount >= 2) {
							console.log('Maximum retries reached');
						}
					} else {
						// Fix: No mismatch case
						console.log(`panel mismatch ${panelMismatches}`);
						break;
					}
				}
				resolve(allPanelsMonthlyDetails);
			}
			catch (err) {
				reject(err);
			}
		});
	}

	const getMonthlyDetailsQuery = useQuery(`monthlyDetails-${uuid}`, async () => {
		const allPanelsMonthlyDetails = await fetchMonthlyDetails(uuid, selectedPanelKey);
		return allPanelsMonthlyDetails;
	}, {
		onError: () => {
			toast.error('Monthly energy details can\'t be fetched');
		},
		cacheTime: 0, refetchOnWindowFocus: false, enabled: false, retry: 1,
	});

	const getHourlyEnergyQuery = useQuery(`hourlyEnergy-${uuid}`, async () => {
		const hourlyEnergyPerSegment = await getHourlyEnergy(uuid, selectedPanelKey);
		return hourlyEnergyPerSegment;
	}, {
		onError: (e) => {
			console.log('hourly energy', e);
		},
		cacheTime: 0, refetchOnWindowFocus: false, enabled: false, retry: 1,
	});

	const saveDesignQuery = useQuery(
		'save',
		async () => {
			let panelsForMonthlyCalculation: { [key: string]: perPanelEnergyDetails } | undefined = getMonthlyDetailsQuery.data;
			let hourlyEngeryTOFAndSolarAccessData = getHourlyEnergyQuery.data;
			if (!panelsForMonthlyCalculation) {
				panelsForMonthlyCalculation = (await getMonthlyDetailsQuery.refetch()).data;
			}
			if (!hourlyEngeryTOFAndSolarAccessData) {
				hourlyEngeryTOFAndSolarAccessData = (await getHourlyEnergyQuery.refetch()).data;
			}
			const { design, selectedPanels } = getEnergyData(
				energy,
				roofSegsFromWhichUserHasSelectedPanels,
				roofSegs,
				offset,
				panel,
				panelsForMonthlyCalculation,
				uuid,
				energyDerateRate,
				hourlyEngeryTOFAndSolarAccessData,
			);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { facets, ...productionData } = design;

			const designSaveObj = {
				selectedPanels: Array.from(selectedPanels),
				productionData: {
					energy: productionData.energy,
					systemSize: productionData.systemSize,
					offset: productionData.offset,
					totalPanels: productionData.totalPanels,
				},
				billAmount: productionData.billAmount,
				annualEnergy: productionData.annualUsage,
				panelKey: productionData.panelKey,
				showToggleForAllPanels: showAllPanels,
				toolEditConfig: {
					zoomLevel: setZoomlevelOnSave(zoomLevel)
				},
				source: imagerySource
			};
			await updateDesing(designSaveObj, uuid);
			return design;
		},
		{
			onSuccess: (design) => {
				dispatch(setUserHasClickedSave({ enabled: true }));
				// dispatch(setIsSyncedWithParent({ synced: true }));
				const [lat, lng] = latLng.split(',');
				const img = getDesignImage();
				// console.log('Now isSyncedWithParent: ', isSyncedWithParent);
				const designData: CustomEventInit = {
					design: { 
						...design, 
						img, 
						lat: +lat, 
						lng: +lng, 
						imagerySource, 
						solarDataSource:  mode === DESIGN_MODES.DEFAULT ? 'sunroof': 'nrel' },
					onlySync: false,
				};
				console.log('dess', designData);
				window.parent.postMessage(
					{ id: 'SOLARQUOTEEMBED_SAVE_HOUSE', detail: designData },
					'*'
				);
				// if(forOnlySync){
				// 	dispatch(toggleInfoModalState({open: false, infoMessage: ''}));
				// }
				// setForOnlySync(false);
			},
			onError: () => {
				toast.error('something went wrong, please try again');
			},
			enabled: false,
		}
	);

	function getDesignImage() {
		if (konvaRef?.current) {
			adjustScalingWhileSavingDesignImage(konvaRef.current);
			return changeVisibilityOfPanelsAndGetCanvasImage(konvaRef.current, false);
		}
		const canvas = document.getElementById('SOLARQUOTEEMBED-CANVAS') as HTMLCanvasElement;
		return canvas?.toDataURL();
	}

	const getPanelDetailsForOrg = useQuery(
		`panelsKeys-${org.panelKeys?.length || 0}`,
		async () => {
			return await getPanelDetails();
		},
		{
			refetchOnWindowFocus: false,
			enabled: !!org.panelKeys?.length,
			retry: 2,
		}
	);

	async function savePanels() {
		resetIrradianceAndThreeDMode();
		window.parent.postMessage({ id: PARENT_EVENTS.SAVE_DESIGN_TRIGGER }, '*');

		const panelsForMonthlyCalculation: { [key: string]: perPanelEnergyDetails } | undefined = (await getMonthlyDetailsQuery.refetch()).data;
		const hourlyEngeryTOFAndSolarAccessData = (await getHourlyEnergyQuery.refetch()).data;
		const { selectedPanels } = getEnergyData(
			energy,
			roofSegsFromWhichUserHasSelectedPanels,
			roofSegs,
			offset,
			panel,
			panelsForMonthlyCalculation,
			uuid,
			energyDerateRate,
			hourlyEngeryTOFAndSolarAccessData
		);
		if (selectedPanels.length < 3) {
			showSelectedPanelsErrorToast();
			return;
		}
		// setForOnlySync(false);
		disableFullView();
		setTimeout(() => {
			saveDesignQuery.refetch();
		}, 100);
	}

	function resetIrradianceAndThreeDMode() {
		dispatch(setIrrandiance({ showIrradiance: false }));
		dispatch(batchUpdateToolState({
			threeDModelEnabled: false,
			// konvaStagePosition: DEFAULT_KONVA_STAGE_POSITION,
		}));
	}

	function disableFullView() {
		dispatch(batchUpdateToolState({ fullScreenEnabled: false, konvaStagePosition: DEFAULT_KONVA_STAGE_POSITION }));
		window.parent.postMessage(
			{ id: PARENT_EVENTS.FULL_VIEW_TOGGLE, detail: { fullScreenEnabled: false } },
			'*'
		);
	}

	// useEffect(() => {
	// 	if (!isSyncedWithParent) {
	// 		setForOnlySync(true);
	// 		dispatch(toggleInfoModalState({open: true, infoMessage: 'Syncing the tool'}));
	// 		saveDesignQuery.refetch();
	// 	}
	// }, []);

	return (
		<RightSidePanel className={fullScreenEnabled ? 'fullScreenView' : 'defaultScreenView'}>
			{!showMobileView && <DesktopViewWrap className={`${fullScreenEnabled ? 'fullscreeview-wrap' : 'screeview-wrap'}`}>
				<DesignModesSelector
					designMode={mode}
					designUUID={uuid}
					panelKey={panel.key}
					energyDerateRate={energyDerateRate}
					stateCode={stateCode}
				/>
				<SideModal />
				<AllPanelsToggle />
			</DesktopViewWrap>}
			{/* TODO: hide according to screen Size (display none will have setbacks) */}
			{showMobileView ?
				<MobileViewWrap>
					<PanelDetailDiv style={{paddingBottom: '0px', paddingTop: '1rem'}}>
						<DesignModesSelector
							designMode={mode}
							designUUID={uuid}
							panelKey={panel.key}
							energyDerateRate={energyDerateRate}
							stateCode={stateCode}
						/>
						<AllPanelsToggle />
					</PanelDetailDiv>
					{isParentSolarQuote && (
						<SQMobileViewWrap>
							<PanelDetailDiv>
								<SystemSize energy={energy} />
							</PanelDetailDiv>
							<PanelDetailDiv>
								<Offset energy={energy} />
							</PanelDetailDiv>
						</SQMobileViewWrap>
					)}
					{!isParentSolarQuote && (
						<MobileViewWrap>
							<PanelDetailDiv>
								<SystemSize energy={energy} />
								<Production energy={energy} />
								<Offset energy={energy} />
							</PanelDetailDiv>
							<PanelDetailDiv>
								<ElectricBillInput setError={setError} />
								{org.setting.acceptsAnnualEnergy && <AnnualUsage setError={setError} isMobileView={showMobileView} />}
							</PanelDetailDiv>
							<PanelDetailDiv>
								<ChangePanelSelector getPanelDetailsForOrgData={getPanelDetailsForOrg.data} selectedPanelKey={selectedPanelKey} />
							</PanelDetailDiv>
							{!org.setting?.isB2C && <PanelDetailDiv>
								<AdjustPanelColorToggle />
							</PanelDetailDiv>}
							<PanelDetailDiv>
								{!org.setting?.isB2C && <RecommendPanelsButton/>}
							</PanelDetailDiv>

							<SaveDesignBtn
								disabled={error || disableOnEdit}
								onClick={savePanels}
							/>
							<SideModal/>
						</MobileViewWrap>
					)}
				</MobileViewWrap>
				:
				// ===== Desktop =====
				<DesktopViewWrap>
					<PanelDetailDiv fullScreenEnabled={fullScreenEnabled}>
						<SystemSize energy={energy} />
						<Production energy={energy} />
						<Offset energy={energy} />
						<ElectricBillInput setError={setError} />
						{org.setting.acceptsAnnualEnergy && <AnnualUsage setError={setError} isMobileView={showMobileView} />}
						<ChangePanelSelector getPanelDetailsForOrgData={getPanelDetailsForOrg.data} selectedPanelKey={selectedPanelKey} />
						{!org.setting?.isB2C && <AdjustPanelColorToggle />}
					</PanelDetailDiv>
					<SaveDesignBtn
						disabled={error || disableOnEdit}
						onClick={savePanels}
					/>
					{/* <SideModal /> */}
					<NextSteps />
				</DesktopViewWrap>
			}
		</RightSidePanel>
	);
}