import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setAddPanel, setDeleteIndividualPanelsMode, setPanelMove, togglePanelModal } from 'store/slices/PanelSlice';

export default function CancelBtn() {
	const {enableAddPanel, deleteIndividualPanelMode, newlyAddedPanels, 
		deletedPanels, enablePanelMove, currentMovablePanels } = useSelector((state: RootState) => state.panelSlice.data);
	const showCancelModalIfDesignHasModificaton= !!newlyAddedPanels.length || !!deletedPanels.length || !!currentMovablePanels.length;

	const dispatch = useDispatch();

	function getTargetModal(){
		if(enableAddPanel) return 'CANCEL_ADD_PANEL';
		if(deleteIndividualPanelMode) return 'CANCEL_DELETE_PANEL';
		if(enablePanelMove) return 'CANCEL_MOVE_PANEL';
		return '';
	}

	function handleClick(){
		if(showCancelModalIfDesignHasModificaton){
			const targetModal = getTargetModal();
			dispatch(togglePanelModal({targetModal, val:true}));
			return;
		}

		if (enableAddPanel) {
			dispatch(setAddPanel({ shouldEnable: false }));
		} else if (deleteIndividualPanelMode) {
			dispatch(setDeleteIndividualPanelsMode({ enabled: false }));
		} else if (enablePanelMove) {
			dispatch(setPanelMove({ shouldEnablePanelMove: false }));
		}
		
	}
	return (
		<Button
			onClick={handleClick}
			className="cancel-btn"
			style={{
				background: 'transparent',
				border: '1px solid rgb(213, 213, 213)',
				color: '#4A525A',
				fontSize: '0.875rem',
				padding: '0px .35rem',
				height: '36px',
				minWidth: '36.5px',
				borderRadius:'20px',
				boxShadow: 'none',
			}}
		>
			<svg width="13" height="13" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.71132 4.50043L8.73856 1.47233C8.82035 1.39334 8.88559 1.29884 8.93048 1.19436C8.97536 
								1.08988 8.99898 0.97751 8.99997 0.863803C9.00096 0.750096 8.97929 0.637331 8.93623 0.532087C8.89317 
								0.426843 8.82958 0.331229 8.74918 0.250823C8.66877 0.170416 8.57316 0.106829 8.46791 0.0637703C8.36267 
								0.0207117 8.2499 -0.000955751 8.1362 3.23334e-05C8.02249 0.00102042 7.91012 0.0246443 7.80564 0.0695253C7.70116 
								0.114406 7.60667 0.179646 7.52767 0.261437L4.49957 3.28868L1.47233 0.261437C1.39334 0.179646 1.29884 0.114406 
								1.19436 0.0695253C1.08988 0.0246443 0.97751 0.00102042 0.863803 3.23334e-05C0.750096 -0.000955751 0.637331 
								0.0207117 0.532087 0.0637703C0.426843 0.106829 0.331229 0.170416 0.250823 0.250823C0.170416 0.331229 0.106829 
								0.426843 0.0637703 0.532087C0.0207117 0.637331 -0.000955751 0.750096 3.23334e-05 0.863803C0.00102042 0.97751 
								0.0246443 1.08988 0.0695253 1.19436C0.114406 1.29884 0.179646 1.39334 0.261437 1.47233L3.28868 4.49957L0.261437 
								7.52767C0.179646 7.60667 0.114406 7.70116 0.0695253 7.80564C0.0246443 7.91012 0.00102042 8.02249 3.23334e-05 
								8.1362C-0.000955751 8.2499 0.0207117 8.36267 0.0637703 8.46791C0.106829 8.57316 0.170416 8.66877 0.250823 
								8.74918C0.331229 8.82958 0.426843 8.89317 0.532087 8.93623C0.637331 8.97929 0.750096 9.00096 0.863803 8.99997C0.97751 
								8.99898 1.08988 8.97536 1.19436 8.93048C1.29884 8.88559 1.39334 8.82035 1.47233 8.73856L4.49957 5.71132L7.52767 
								8.73856C7.60667 8.82035 7.70116 8.88559 7.80564 8.93048C7.91012 8.97536 8.02249 8.99898 8.1362 8.99997C8.2499 
								9.00096 8.36267 8.97929 8.46791 8.93623C8.57316 8.89317 8.66877 8.82958 8.74918 8.74918C8.82958 8.66877 8.89317 
								8.57316 8.93623 8.46791C8.97929 8.36267 9.00096 8.2499 8.99997 8.1362C8.99898 8.02249 8.97536 7.91012 8.93048 
								7.80564C8.88559 7.70116 8.82035 7.60667 8.73856 7.52767L5.71132 4.50043Z" fill="#4A525A" />
			</svg></Button>
	);

}