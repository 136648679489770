import Slider from '../Slider/Slider';
import { useEffect, useRef } from 'react';
import {
	SliderWrap, RangeValueWrap, RangeWrap, StripButoonWrap,
	ToolHeaderMainWrap, SliderWrapper, EditButtonLaptop, EditButtonMobile, EditButtonWrap
} from './styles';
import OffsetModal from '../OffsetModal/';
import { useRefDispatch } from 'contexts/RefContext';
import AllPanelsToggle from 'components/DisplayEnergy/AllPanelsToggle';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IrradianceToggle } from 'components/Irradiance/IrradianceToggle';
import DeleteModal from 'components/tool/Editor/DeleteModal';
import EditFacetModal from 'components/tool/Editor/EditFacetModal';
import ChangeOrientation from 'components/tool/Editor/ChangeOrientation';

import PanelToggle from 'components/Irradiance/PanelToggle';
import Show3DButton from 'components/ThreeDImage/Show3DButton';
import { EditButtonDiv } from 'components/tool/Editor/ButtonDiv';
import WarningModal from 'components/tool/Editor/WarningModal';
import EditorToolBar from 'components/tool/Editor';
import SwitchProvider from 'components/SwitchProvider';
import FullViewBtn from 'components/tool/Editor/ButtonDiv/FullViewBtn';
import ShadingConfirmationModal from 'components/tool/Editor/EditFacetModal/shadingConfirmationModal';
import Settings from 'components/Settings';
import RecommendPanelsButton from 'components/DisplayEnergy/PanelComponent/RecommendPanelsButton';

export default function ToolWithSilder() {
	const ref = useRef<HTMLInputElement | null>(null);
	const refDispatch = useRefDispatch();
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { org } = useSelector((state: RootState) => state.roofData.data);
	const { editModeEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { accessToSettings } = useSelector((state: RootState) => state.QueryParams.data);
	const showAdjustPanelSlider = !editModeEnabled && !showIrradiance;

	useEffect(() => {
		refDispatch({ type: 'ADD_SLIDER_REF', payload: { ref } });
	}, [refDispatch]);

	return (
		<>
			<OffsetModal />
			<DeleteModal />
			<ChangeOrientation />
			<EditFacetModal />
			{/* <InfoModal/> */}
			<WarningModal />
			<ShadingConfirmationModal />
			<SliderWrapper className={editModeEnabled ? 'EnableModeViewWrapper' : 'NonViewWrapper'}>
				<SliderWrap className={editModeEnabled ? 'EnableSliderWrapper' : 'NonEnableSliderWrapper'}>
				
					{!!org.setting?.canEdit &&
						<EditButtonWrap>
							<EditButtonDiv />
						</EditButtonWrap>}
					{!org?.setting?.isB2C && !editModeEnabled && <EditButtonLaptop >
						<SwitchProvider />
					</EditButtonLaptop>}
					{editModeEnabled && <EditorToolBar />}

					{showAdjustPanelSlider &&
						<>
							<div className='sliderWithName'>
								<div className='adjust-panel-container'>
									<h3>Adjust Panel Here</h3>
								</div>
								<RangeValueWrap>
									<RangeWrap>
										<Slider ref={ref} />
									</RangeWrap>
								</RangeValueWrap>
							</div>
							{/* <div className='hide-in-desktop mobileoffset'> */}
							{/* {isParentSolarQuote && <PanelTitle>
									<h4>Offset</h4>
									<h2 className="primary-color">{`${offset}%`}</h2>
								</PanelTitle>} */}
							{/* <AllPanelsToggle /> */}
							{/* </div> */}
						</>

					}

				</SliderWrap>

				{!org?.setting?.isB2C && <ToolHeaderMainWrap className={editModeEnabled ? 'EnableModeView' : 'NonView'}>
					<EditButtonMobile ><SwitchProvider /></EditButtonMobile>

					<ToolHeaderMainWrap className='mobile-view'>
						{accessToSettings && <Settings/>}
						{showIrradiance &&
							<StripButoonWrap className='stripWrapperButtons'>
								<PanelToggle />
							</StripButoonWrap>
						}
						{!org.setting?.isB2C && <RecommendPanelsButton/>}
						<IrradianceToggle />
						<Show3DButton />
						<FullViewBtn />

					</ToolHeaderMainWrap>

				</ToolHeaderMainWrap>

				}

			</SliderWrapper>

		</>
	);
}