import { useRefs } from 'contexts/RefContext';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useRasterDims from './useRasterDims';

export function useKonvaScale() {
	const { konvaRef } = useRefs();
	const { imgHeight, imgWidth } = useSelector((state: RootState) => state.roofData.data);
	const rasterDims = useRasterDims();

	const scale = useMemo(() => {
		let x = 1, y = 1;
		if (konvaRef?.current) {
			x = (rasterDims.width/ imgWidth);
			y = (rasterDims.height/ imgHeight);
		}
		return { x, y };
	}, [imgHeight, imgWidth, konvaRef, rasterDims]);

	return scale;
}
