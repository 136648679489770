import { FC, ButtonHTMLAttributes, ReactNode, isValidElement, Children, cloneElement, useState } from 'react';
import { ToggleButtonStyle, ToggleGroupContainerStyle } from './style';

interface ToggleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	value: string;
	isActive?: boolean;
	size?: 'small' | 'medium' | 'large';
}
const ToggleButton: FC<ToggleButtonProps> = ({ value, isActive = false, size = 'medium', children, ...props }) => {
	return (
		<ToggleButtonStyle className={isActive? 'active': ''} isActive={isActive} size={size} {...props} value={value}>
			{children}
		</ToggleButtonStyle>
	);
};

interface ToggleGroupProps {
	children: ReactNode;
	// usg single select for now
	// isSingleSelect?: boolean;
	onChange?: (value: string) => void;
	defaultValue?: string;
}

const ToggleGroup: FC<ToggleGroupProps> = ({ children, onChange, defaultValue }) => {
	const [valueSelected, setValueSelected] = useState<string | undefined>(defaultValue);
	const handleClick = (toggleValue: string) => {
		onChange?.(toggleValue);
		setValueSelected(toggleValue);
	};

	const renderedChildren = Children.map(children, (child) => {
		if (isValidElement<ToggleButtonProps>(child)) {
			const isActive = valueSelected === child.props.value;
			return cloneElement(child, {
				isActive,
				onClick: () => handleClick(child.props.value),
			});
		}
		return child;
	});

	return <ToggleGroupContainerStyle>{renderedChildren}</ToggleGroupContainerStyle>;
};

export { ToggleButton, ToggleGroup };