import Irradiance from 'components/Irradiance';
import MonthButtons from 'components/Irradiance/MonthButtons';
import Loader from 'components/Loader';
import MoveButton from 'components/MoveButton';
import ThreeDImage from 'components/ThreeDImage';
import ZoomButtons from 'components/ZoomButtons';
import { useRefDispatch, useRefs } from 'contexts/RefContext';
import useFirstRender from 'hooks/useFirstRender';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { useEffect, useLayoutEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Layer, Stage } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setKonvaStagePosition, setZoomLevel } from 'store/slices/ToolSlice';
import { StripProduction } from './Editor/style';
import PanelsGroup from './PanelsGroup/PanelsGroup';
import PanelDrawer from './Raster/PanelDrawer';
import { FlexDiv, MoveZoomWrapper, StageWrap, StripProductionWrap } from './styles';
import { applyDisplacementBound, getNewPositonByApplyiingScaleFromCenter, zoomAtAPosition } from './util';
import PanelRect from './Panel/Rect';
import { DEFAULT_KONVA_STAGE_POSITION, FULL_VIEW_POSITION_SHIFT_FACTOR, DESIGN_MODES, BREAK_POINT_FOR_MOBILE_VIEW } from '../../constants';
import SetbackArea from './SetbackArea';
import Obstructions from './PanelsGroup/Obstructions';
import { fetchIrradianceImages } from 'store/slices/IrradianceSlice';
import useKonvaStageDimensions from 'hooks/useKonvaStageDims';

function Tool() {
	const layerRef = useRef<Konva.Layer | null>(null);
	const { imgWidth, mode, uuid } = useSelector((state: RootState) => state.roofData.data);

	const stageRef = useRef<Konva.Stage | null>(null);
	const stageWrapRef = useRef<HTMLDivElement | null>(null);
	const canvasWrapRef = useRef<HTMLDivElement | null>(null);
	const [threeDModelReady, setThreeDModelReady] = useState(false);

	const { threeDModelEnabled, zoomLevel, konvaStagePosition,
		moveStage, drawModeEnabled, fullScreenEnabled, editModeEnabled, groundMountEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { enableAddPanel } = useSelector((state: RootState) => state.panelSlice.data);
	const refDispatch = useRefDispatch();
	const { showIrradiance, showPanels, showProgress, irradianceImages } = useSelector((state: RootState) => state.Irradiance.data);
	const showPanelsInIrradiancewMode = !showIrradiance || (showIrradiance && showPanels);
	const showLoader = (threeDModelEnabled && !threeDModelReady) || (showProgress && showIrradiance);
	const dispatch = useDispatch<AppDispatch>();
	const isFirstRender = useFirstRender();
	const { movablePanelRef } = useRefs();
	const konvaStageDims = useKonvaStageDimensions({stageWrapRef});

	const handleMouseScroll = (event: KonvaEventObject<WheelEvent>) => {
		event.evt.preventDefault();
		const stage = stageRef.current;
		if (!stage) return;
		const type = event.evt.deltaY > 0 ? 'out' : 'in';
		const updatedZoomLevelAndPositon = zoomAtAPosition(stage, stage.getPointerPosition()!, type);
		if (!updatedZoomLevelAndPositon) return;
		dispatch(setZoomLevel(updatedZoomLevelAndPositon));
	};

	const adjustStageDimsANdPostion = useCallback(() => {
		const stage = stageRef?.current;
		if (!stage) return;

		const { width, height } = konvaStageDims;
		stage.height(height);
		stage.width(width);

		let position = { ...DEFAULT_KONVA_STAGE_POSITION };
		const posShift = width * FULL_VIEW_POSITION_SHIFT_FACTOR;

		if (fullScreenEnabled && width > imgWidth) {
			position.x = posShift;
		}
		if (isFirstRender && !fullScreenEnabled) {
			position = getNewPositonByApplyiingScaleFromCenter(stage);
		}
		dispatch(setKonvaStagePosition({ position }));
	}, [dispatch, fullScreenEnabled, imgWidth, isFirstRender, konvaStageDims]);

	useLayoutEffect(() => {
		const canvasWrapElement = canvasWrapRef.current;
		if (!canvasWrapElement) {
			return;
		}
		canvasWrapElement.style.width = `${konvaStageDims.width}px`;
		canvasWrapElement.style.height = `${konvaStageDims.height}px`;
	}, [konvaStageDims]);

	useEffect(() => {
		refDispatch({ type: 'ADD_KONVA_REF', payload: { ref: stageRef } });
	}, [stageRef, refDispatch]);

	useEffect(() => {
		if (layerRef.current) {
			window.parent.postMessage({ id: 'SOLARQUOTEEMBED_DATA_LOADED' }, '*');
			layerRef.current.getCanvas()._canvas.id = 'SOLARQUOTEEMBED-CANVAS';
		}
	},[]);

	useEffect(() => {
		if (!threeDModelEnabled) {
			adjustStageDimsANdPostion();		
		}
	}, [adjustStageDimsANdPostion, threeDModelEnabled, threeDModelReady]);

	useEffect(() => {
		if (!threeDModelEnabled) {
			setThreeDModelReady(false);
		}
	}, [threeDModelEnabled]);

	useEffect(()=>{
		if (!showProgress && !irradianceImages?.length && mode == DESIGN_MODES.DEFAULT) {
			dispatch(fetchIrradianceImages({ uuid }));
		}

	},[dispatch, irradianceImages?.length, mode, uuid, showProgress]);

	const cursorStyle = useMemo(() => {
		switch (true) {
		case moveStage:
			return 'grab';
		case drawModeEnabled:
			return 'crosshair';
		default:
			return 'default';
		}
	}, [drawModeEnabled, moveStage]);

	function setDragBoundToStage(pos: Vector2d) {
		if (!stageRef.current) return pos;
		const newPos = applyDisplacementBound(stageRef.current, pos);
		dispatch(setKonvaStagePosition({ position: newPos }));
		return pos;
	}

	function handlePointerDown(){
		if(!enableAddPanel || !stageRef.current || moveStage ) return;
		
		const position = stageRef.current?.getPointerPosition();
		const stageScale = stageRef.current.scale() as Konva.Vector2d;
		if(!position) return;
		
		const adjustedPointerPosition = {
			x: (position.x - stageRef.current.x()) / stageScale.x,
			y: (position.y - stageRef.current.y()) / stageScale.y,
		};
		movablePanelRef?.current?.visible(true);
		movablePanelRef?.current?.position(adjustedPointerPosition);
		movablePanelRef?.current?.startDrag();
	}

	function handlePointerUp() {
		if (movablePanelRef?.current) {
			movablePanelRef.current.stopDrag();
		}
	}

	return (
		<StageWrap ref={stageWrapRef}>
			<FlexDiv ref={canvasWrapRef}>
				{(showIrradiance && !threeDModelEnabled) && <MonthButtons />}

				{!threeDModelReady &&
					<Stage
						scaleX={zoomLevel}
						scaleY={zoomLevel}
						ref={stageRef}
						draggable={moveStage}
						onWheel={handleMouseScroll}
						x={konvaStagePosition.x }
						y={konvaStagePosition.y }
						onDragStart={() => {
							if (!stageRef.current || !moveStage) return;
							stageRef.current.container().style.cursor = 'grabbing';
						}}
						onDragEnd={() => {
							if (!stageRef.current || !moveStage) return;
							stageRef.current.container().style.cursor = 'grab';
						}}
						dragBoundFunc={setDragBoundToStage}
						onPointerDown={handlePointerDown}
						onPointerUp={handlePointerUp}
						style={{ cursor: cursorStyle}}
					>
						<Layer ref={layerRef} >
							<>
								<PanelDrawer showAzimuthArrows={mode === DESIGN_MODES.NEARMAP_NREL_MANUAL || groundMountEnabled }/>
								{showIrradiance && <Irradiance layerRef={layerRef} />}
								{showPanelsInIrradiancewMode && <PanelsGroup />}
								{enableAddPanel && <PanelRect />}
								{editModeEnabled && <SetbackArea/>}
								{ editModeEnabled && <Obstructions/> }
								
							</>
						</Layer>
					</Stage>
				}
				{showLoader && <Loader styles={{ position: 'absolute', background: '#ffffff8f', top: '0px' }} />}

				{threeDModelEnabled && <div style={{ width: konvaStageDims.width, height: konvaStageDims.height, 
					position: showLoader && threeDModelEnabled ? 'absolute' : 'relative', }}>
					<ThreeDImage setThree3ModelReady={setThreeDModelReady} />
				</div>}
		
				<StripProductionWrap fullScreenEnabled={fullScreenEnabled}>
					<StripProduction />
				</StripProductionWrap>
				{!threeDModelEnabled &&
					<MoveZoomWrapper showOnLeft={showIrradiance} fullScreenEnabled={fullScreenEnabled} className='zoomWrapper'>
						<MoveButton />
						<ZoomButtons />
					</MoveZoomWrapper>
				}
			</FlexDiv>
		</StageWrap>
	);
}

export default Tool;