import Button from 'components/Basic/Button';
import { ModalContainer, ModalContent, ModalFooter, ModalHeader, SettingsPopup } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { DesignSettings, toggleSettingsModal } from 'store/slices/DesignSettingsSlice';
import { AppDispatch, RootState } from 'store';
import Setback from './Setbacks';
import ConfirmationModal from './Confirmation';
import { useRef, useState } from 'react';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { inchesToDecimeters } from 'store/slices/DesignSettingsSlice/util';

export default function Settings() {

	const { openModal, setbacks, applyingDesignSettings } = useSelector((state: RootState) => state.designSettigs.data);
	const roofData = useSelector((state: RootState) => state.roofData.data);
	const { uuid, panel, imagerySource } = roofData;
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [disableApplyBtn, setDisableApplyBtn] = useState<boolean>(false);

	const disptach = useDispatch<AppDispatch>();

	const setbacksRef = useRef<DesignSettings['setbacks']>(setbacks);

	function handlePopupCancel() {
		disptach(toggleSettingsModal({ open: false }));
	}

	function handleConfirmation() {
		setShowConfirmation(false);
		const { gutterSetback, nonGutterSetback, obstructionSetback } = setbacksRef.current;
		disptach(changePanel({
			uuid, panelKey: panel.key, source: imagerySource,
			gutterSetback: inchesToDecimeters(gutterSetback),
			nonGutterSetback: inchesToDecimeters(nonGutterSetback),
			obstructionSetback: inchesToDecimeters(obstructionSetback)
		}));
	}

	function handleSetbackChnage(setback: Partial<DesignSettings['setbacks']>, isValid: boolean) {
		setDisableApplyBtn(!isValid);
		if (!isValid) return;
		setbacksRef.current = {
			...setbacksRef.current,
			...setback
		};
	}

	function handleApplyChanges() {
		let hasInputChanged = false;
		Object.keys(setbacksRef.current).forEach((k) => {
			const setbackKey = k as keyof DesignSettings['setbacks'];
			if (setbacksRef.current[setbackKey] !== setbacks[setbackKey]) {
				hasInputChanged = true;
				return;
			}
		});

		if (hasInputChanged) {
			setShowConfirmation(true);
			return;
		}

		handlePopupCancel();
	}

	return (
		<>
			<Button
				id="settings"
				className='btnHover'
				style={{
					border: '1px solid #c7cacf',
					fontSize: '0.938rem',
					height: '36px',
					width: '36px',
					borderRadius: '100%',
					background: 'rgb(255, 255, 255)',
					color: '#64686a',
					padding: '10px',
				}} onClick={() => {
					disptach(toggleSettingsModal({ open: true }));
				}}
				tooltipContent='Settings'
			>
				<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M16.1579 2.68421H13.6316M10.2632 1V4.36842M10.2632 2.68421H1M4.36842 8.57895H1M7.73684 6.89474V10.2632M17 8.57895H7.73684M16.1579 14.4737H13.6316M10.2632 12.7895V16.1579M10.2632 14.4737H1" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
				{/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width="25">
					<path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 
				1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 
				1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125
				0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26
				1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47
				0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 
				0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 
				1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 
				0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 
				1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 
				1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
					<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
				</svg> */}

			</Button>

			<SettingsPopup
				open={openModal}
				closeOnDocumentClick={false}
				onClose={() => disptach(toggleSettingsModal({ open: false }))}
			>
				<ModalContainer>
					<ModalHeader>
						Settings
					</ModalHeader>
					<ModalContent>
						<Setback onSetbackChnage={handleSetbackChnage} />
					</ModalContent>

					<ModalFooter>
						<Button disabled={applyingDesignSettings}
							onClick={handlePopupCancel} style={{ backgroundColor: 'var(--label)' }}> Cancel</Button>
						<Button disabled={applyingDesignSettings || disableApplyBtn}
							showLoader={applyingDesignSettings}
							onClick={handleApplyChanges} style={{ borderColor: 'var(--primary)' }}> Apply </Button>
					</ModalFooter>
				</ModalContainer>

			</SettingsPopup>

			<ConfirmationModal onCancel={() => setShowConfirmation(false)} onConfirm={handleConfirmation} open={showConfirmation} />
		</>
	);
}