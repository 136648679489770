import { useRefs } from 'contexts/RefContext';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export default function useRasterDims(): Dimensions {
	const { konvaRef } = useRefs();
	const { imgHeight, imgWidth, org } = useSelector((state: RootState) => state.roofData.data);

	const maintainAspectRatio = !!org?.setting?.canEdit;

	const konvaStateWidth = konvaRef?.current?.width() || imgWidth;
	const konvaStageHeight = konvaRef?.current?.height() || imgHeight;

	const imgDims = useMemo(() => {
		if (konvaRef?.current) {
			const aspectRatio = imgWidth / imgHeight;
			const height = konvaStageHeight < imgHeight ? imgHeight : konvaStageHeight;
			const width = maintainAspectRatio ? height * aspectRatio : konvaStateWidth;
			return { height, width };
		}
		return { height: imgHeight, width: imgWidth };
	}, [konvaRef, imgHeight, imgWidth, maintainAspectRatio, konvaStageHeight, konvaStateWidth]);
	return imgDims;
}