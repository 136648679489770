import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import Konva from 'konva';
import { Image } from 'react-konva';
import { setIrrandiance } from 'store/slices/IrradianceSlice';
import useRasterDims from 'hooks/useRasterDims';

interface Props {
	layerRef: React.MutableRefObject<Konva.Layer | null>
}
export default function Irradiance({ layerRef }: Props) {
	const { imgHeight, imgWidth } = useSelector((state: RootState) => state.roofData.data);
	const { selectedMonth, irradianceImages } = useSelector((state: RootState) => state.Irradiance.data);
	const image = useMemo(() => new window.Image(imgWidth, imgHeight), [imgWidth, imgHeight]);
	const [imageLoaded, setImageLoaded] = useState<boolean>(false);

	const dispatch = useDispatch();
	const imgDims = useRasterDims();

	useEffect(() => {
		function handleImageLoad() {
			layerRef?.current?.batchDraw();
			setImageLoaded(true);
		}

		function handleImageError() {
			dispatch(setIrrandiance({ showIrradiance: false }));
			console.log('irradiance error');
		}

		if (irradianceImages?.length && layerRef?.current) {
			const base64 = irradianceImages[selectedMonth];
			image.src = `data:image/png;base64,${base64}`;
			image.onload = handleImageLoad;
			image.onerror = handleImageError;
		}
		return () => {
			image.onload = null;
			image.onerror = null;
			image.src = '';
		};
	}, [selectedMonth, irradianceImages, layerRef, dispatch, image]);

	return (
		<>
			{imageLoaded && <Image
				image={image}
				preventDefault={false}
				height={imgDims.height}
				width={imgDims.width}
			/>}
		</>
	);
}
