import { NewlyCreatedFacet } from 'store/slices/ToolSlice/types';
import { SliderInput, SliderWrap } from 'components/Slider/styles';
import { ToggleButton, ToggleGroup } from 'components/Basic/ToggleGroup';
import { useLayoutEffect, useRef, useState } from 'react';
import { toggleManualToolDoneButtonState } from 'store/slices/ManualToolModal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { ModalFacetBox, HeadingAzimuth, ShadingWrap } from './style';

function AzimuthDiv(props: { azimuth: number }) {
	const roundedValue = props.azimuth ? Math.round(props.azimuth) : 'Not Assigned yet';
	return (
		<HeadingAzimuth>
			Azimuth: 
			<span>{roundedValue}</span>
		</HeadingAzimuth >
	);
}

const getSliderlinearGrad = (val: number, active:boolean) => `linear-gradient(to right, ${active? 'var(--secondary)': '#8497AE'} 0%, ${active? 'var(--secondary)': '#8497AE'} ${val}%, #BCBDC6 ${val}%, #BCBDC6 100%)`;
function PitchSlider(props: { pitch: number, onChange?: (value: string) => void , active: boolean}) {
	const sliderRef = useRef<HTMLInputElement | null>(null);
	const [pitchval, setPitchVal]= useState<number>(props.pitch);

	useLayoutEffect(() => {
		if (!sliderRef.current) return;
		sliderRef.current.style.background = getSliderlinearGrad((props.pitch / 90) * 100, props.active);
	}, [props.active, props.pitch]);

	return (
		<SliderWrap>
			<div className="pitch-label">
				<span>Pitch:</span>
				<span className="pitch-degree">{Math.round(pitchval * 100) / 100}<sup className="degree">0</sup></span>
			</div>
			<SliderInput
				type='range'
				min={0}
				max={90}
				defaultValue={props.pitch}
				step={1}
				ref={sliderRef}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const value = Math.ceil(+e.target.value);
					e.target.value = value.toString();
					setPitchVal(value);
					const rangeVl = (value / 90) * 100;
					if (sliderRef.current)
						sliderRef.current.style.background = getSliderlinearGrad(rangeVl, props.active);
					props.onChange?.(e.target.value);
				}}
			/>
		</SliderWrap>
	);
}

type Props = NewlyCreatedFacet & {
	groupSuffix: string;
	active?: boolean;
	onChange: (id: string, groupSuffix: string) => void;
	onShadingValChange(id: string, groupSuffix: string, value: any): void;
	onPitchValChange(id: string, groupSuffix: string, value: any): void;
};
export default function NonDefaultModeValues(props: Props) {
	const { azimuthDegrees, pitchDegrees, shading = 0, isGroundMount } = props;
	const dispatch = useDispatch<AppDispatch>();
	function handleClick(){
		props.onChange(props.konvaPolygonID, props.groupSuffix);
	}

	return (
		<>
			{
				<ModalFacetBox className={`${props.active? ' active': ''}`} onClick={handleClick}>
					{/* <div className="facet-label_name">Facet A</div> */}
					<AzimuthDiv azimuth={azimuthDegrees!} />

					<div className='facet-slider-wrap facet-slider-slope-info'>
						{
							<PitchSlider 
								pitch={pitchDegrees ?? (isGroundMount ? 18 : 20)}
								active= {!!props.active }
								onChange={(v) => {
									props.onPitchValChange(props.konvaPolygonID, props.groupSuffix, +v);
									props.onChange(props.konvaPolygonID, props.groupSuffix);
									dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: true }));
								}}
							/>
						}
					</div>
					<ShadingWrap className='facet-slope-wrap facet-slider-slope-info'>
						<span>Shading:</span>
						<ToggleGroup
							defaultValue={shading.toString()}
							onChange={(v) => {
								props.onShadingValChange(props.konvaPolygonID, props.groupSuffix, +v);
								props.onChange(props.konvaPolygonID, props.groupSuffix);
								dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: true }));
							}}
						>
							<ToggleButton value="14.08">None</ToggleButton>
							<ToggleButton value="15.85">low</ToggleButton>
							<ToggleButton value="20.28">medium</ToggleButton>
							<ToggleButton value="24.76">high</ToggleButton>
						</ToggleGroup>
					</ShadingWrap>
				</ModalFacetBox>
			}
		</>
	);

}
