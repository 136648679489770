import Button from 'components/Basic/Button';
import { DESIGN_MODES } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setIrrandiance } from 'store/slices/IrradianceSlice';
import { setEditState } from 'store/slices/ToolSlice';

export function IrradianceToggle() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { mode } = useSelector((state: RootState) => state.roofData.data);
	const dispatch = useDispatch();

	function handleIrradianceClick() {
		dispatch(setEditState({ enabled: false }));
		dispatch(setIrrandiance({ showIrradiance: !showIrradiance }));
	}

	if(mode != DESIGN_MODES.DEFAULT){
		return null;
	}

	return <Button style={{
		borderRadius: '20px',
		color: showIrradiance ? '#28373E' : '#64686A',
		background: `${showIrradiance ? 'var(--secondary)' : '#fff'}`,
		border: `${showIrradiance ? '1px solid var(--secondary)' : '1px solid #C7CACF'}`
	}}
	onClick={handleIrradianceClick}
	disabled={threeDModelEnabled}
	className={'btnFont0 btnHover ' + `${showIrradiance ? ' active' : ''}`}>
		<svg width="17" height="18" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="9.37725" cy="10.0048" rx="3.48858" ry="3.50336" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.5"/>
			<ellipse cx="9.37725" cy="10.0048" rx="3.48858" ry="3.50336" stroke={`${showIrradiance ? '#28373E' : '#64686A'}`} strokeWidth="1.5"/>
			<path d="M15.2441 10.2814H17.8333" stroke="#64686a" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M15.2441 10.2814H17.8333" stroke={`${showIrradiance ? '#28373E' : '#64686A'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M0.945312 10.3154H3.53449" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M0.945312 10.3154H3.53449" stroke={`${showIrradiance ? '#28373E' : '#64686A'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.22852 15.8447L9.22852 18.4449" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.22852 15.8447L9.22852 18.4449" stroke={`${showIrradiance ? '#28373E' : '#64686A'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.45117 1.55554L9.45117 4.15569" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.45117 1.55554L9.45117 4.15569" stroke={`${showIrradiance ? '#28373E' : '#64686A'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M13.332 14.3562L15.1629 16.1948" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M13.332 14.3562L15.1629 16.1948" stroke={`${showIrradiance ? '#28373E' : '#64686A'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M3.19727 4.22534L5.02809 6.06393" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M3.19727 4.22534L5.02809 6.06393" stroke={`${showIrradiance ? '#28373E' : '#64686A'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M5.16016 14.0181L3.32933 15.8567" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M5.16016 14.0181L3.32933 15.8567" stroke={`${showIrradiance ? '#28373E' : '#64686A'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M15.3809 4.07275L13.55 5.91134" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M15.3809 4.07275L13.55 5.91134" stroke={`${showIrradiance ? '#28373E' : '#64686A'}`} strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
		<span>Irradiance</span>
	</Button>;

}