import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getAnnualEnergyFromBillAmount, getBillAmountFromAnnualEnergy } from '../../../util';
import { getOffsetIndex } from 'components/tool/util';
import { ChangePanelProps, RoofData, TransformedSolarData } from './types';
import { getInitialState, convertDataToRoofContextData, reCalculateSelectedPanels } from './util';
import { getDesignByUUID } from 'pages/LoadRoofDesign/api';
import { RootState } from 'store';
import { AxiosError } from 'axios';
import { errorResponseHandler } from 'store/api/AxiosError';
import { CustomError } from 'store/api/types';
import { SUBHUB_APP_NAME } from '../../../constants';
import { getPreviousPanelAllignment } from './util/convertRoofData';
import { EnergyState } from '../EnergySlice/types';

export const fetchRoofData = createAsyncThunk('roofData/fetchData',
	async (props: { uuid: string; panelKey?: string, queryParamBillAmount?: number }, thunkApi) => {
		try {
			const { uuid, panelKey, queryParamBillAmount } = props;
			const {electricUnitPrice, gutterSetback, obstructionSetback, nonGutterSetback} = (thunkApi.getState() as RootState).QueryParams.data;
			const parentAppName = (thunkApi.getState() as RootState).QueryParams.data.parentAppName;
			const energyDerateRate = (thunkApi.getState() as RootState).QueryParams.data.energyDerateRate;
			const data = await getDesignByUUID({uuid, panelKey, gutterSetback, obstructionSetback, nonGutterSetback });
			const billAmount = queryParamBillAmount || data.billAmount;
			const appIsEmbeddedInSubhub = parentAppName === SUBHUB_APP_NAME;
			const intialAnnualEnergy = (thunkApi.getState() as RootState).QueryParams.data.annualEnergy;
			const annualEnergy =intialAnnualEnergy ? intialAnnualEnergy : getAnnualEnergyFromBillAmount(billAmount, electricUnitPrice);
			const imagerySource = data.source || (thunkApi.getState() as RootState).toolNewPostions.data.selectedProvider;
			
			const recommendPanels = appIsEmbeddedInSubhub ? getPreviousPanelAllignment : convertDataToRoofContextData;
			const toggle = { 
				orgToggle: !!data.organization?.settings?.showToggleForAllPanels, 
				designToggle: data.showToggleForAllPanels || appIsEmbeddedInSubhub
			};
			const convertedData = recommendPanels(data, annualEnergy, toggle, energyDerateRate, imagerySource);
			convertedData.offsetIndex = getOffsetIndex(convertedData.rasterSolarPanels, annualEnergy, energyDerateRate);
			return {  
				roofData: convertedData,
				userHasGivenPermToExceedOffset: data.userHasGivenPermToExceedOffset,
				metaData: {
					billAmount,
					annualEnergy,
					electricUnitPrice
				},
				energyDerateRate,
				toolEditConfig: data.toolEditConfig
			} as TransformedSolarData;
		} catch (error) {
			console.log('error roof slice',error);
			throw thunkApi.rejectWithValue(errorResponseHandler(error as AxiosError)) as unknown as CustomError;
		}
	});

type PanelAlignmentProps = {
	billAmount?: number;
	energy?: number;
	electricUnitPrice?: number;
}

function getEnergyState(panelAlignmentProps: PanelAlignmentProps, electricUnitPrice: number) {
	const { billAmount, energy } = panelAlignmentProps;
	const energyOutput = {
		currentBillAmount: 0,
		billEnergy: 0,
		annualInputEnergy: 0,
		electricUnitPrice
	};
	if (billAmount) {
		const energy = getAnnualEnergyFromBillAmount(billAmount, electricUnitPrice);
		energyOutput.currentBillAmount = billAmount;
		energyOutput.billEnergy = energy;
		energyOutput.annualInputEnergy = energy;
	}
	if (energy) {
		const billAmount = getBillAmountFromAnnualEnergy(energy, electricUnitPrice);
		energyOutput.currentBillAmount = billAmount;
		energyOutput.billEnergy = energy;
		energyOutput.annualInputEnergy = energy;
	}
	return energyOutput;
}

export const suggestNewPanelAlignment =
	createAsyncThunk('roofData/suggestNewPanelAlignment', async (panelAlignmentProps: PanelAlignmentProps, thunkApi) => {
		console.log('suggestNewPanelAlignment');
		const roofState = (thunkApi.getState() as RootState).roofData.data;
		const electricUnitPrice = (thunkApi.getState() as RootState).QueryParams.data.electricUnitPrice;
		const energyDerateRate = (thunkApi.getState() as RootState).QueryParams.data.energyDerateRate;
		const energyState = getEnergyState(panelAlignmentProps, electricUnitPrice);
		const parentAppName = (thunkApi.getState() as RootState).QueryParams.data.parentAppName;
		const appIsEmbeddedInSubhub = parentAppName === SUBHUB_APP_NAME;
		const toggle = { orgToggle: roofState.org.setting.showToggleForAllPanels, designToggle: roofState.showAllPanels || appIsEmbeddedInSubhub };
		const newPanelSuggestion = reCalculateSelectedPanels(roofState.allPanels,
			roofState.roofSegs, energyState.annualInputEnergy, toggle, energyDerateRate);
		const offsetIndex = getOffsetIndex(newPanelSuggestion.rasterSolarPanels, energyState.annualInputEnergy, energyDerateRate);
		return {
			roofState: {
				...roofState,
				...newPanelSuggestion,
				offsetIndex,
			},
			energyState,
			energyDerateRate
		};
	});

export const recalcuatedEnergyVals =
	createAsyncThunk('roofData/recalcuatedEnergyVals', async (panelAlignmentProps: PanelAlignmentProps, thunkApi) => {
		console.log('recalcuatedEnergyVals');
		const roofState = (thunkApi.getState() as RootState).roofData.data;
		const electricUnitPrice = panelAlignmentProps.electricUnitPrice || (thunkApi.getState() as RootState).QueryParams.data.electricUnitPrice;
		const energyDerateRate = (thunkApi.getState() as RootState).QueryParams.data.energyDerateRate;
		const energyState = getEnergyState(panelAlignmentProps, electricUnitPrice);
		const offsetIndex = getOffsetIndex(roofState.rasterSolarPanels, energyState.annualInputEnergy, energyDerateRate);
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const energySlice = (thunkApi.getState() as RootState).energyData.data!;
		return {
			roofState: {
				...roofState,
				selectedPanels: energySlice.selectedPanels,
				offsetIndex,
			},
			energyState,
			energyDerateRate
		};
	});

export const showAllPanels =
	createAsyncThunk('roofData/showAllPanels', async (props: { toggleValue: boolean }, thunkApi) => {
		console.log('showAllPanels');
		const { toggleValue } = props;
		const roofState = (thunkApi.getState() as RootState).roofData.data;
		const electricUnitPrice = (thunkApi.getState() as RootState).QueryParams.data.electricUnitPrice;
		const energyDerateRate = (thunkApi.getState() as RootState).QueryParams.data.energyDerateRate;
		const panelAlignmentProps = {
			billAmount: (thunkApi.getState() as RootState).energyData.data?.currentBillAmount
		};
		const energyState = getEnergyState(panelAlignmentProps, electricUnitPrice);
		const toggle = { orgToggle: roofState.org.setting.showToggleForAllPanels, designToggle: toggleValue };
		const newPanelSuggestion = reCalculateSelectedPanels(
			roofState.allPanels, roofState.roofSegs, energyState.annualInputEnergy, toggle, energyDerateRate
		);
		const offsetIndex = getOffsetIndex(newPanelSuggestion.rasterSolarPanels, energyState.annualInputEnergy, energyDerateRate);
		return {
			roofState: {
				...roofState,
				...newPanelSuggestion,
				offsetIndex,
				showAllPanels: toggleValue
			},
			energyState,
			energyDerateRate
		};
	});

export const changePanel = createAsyncThunk('roofData/changePanels', 
	async (props: ChangePanelProps, thunkApi) => {
		try {
			const {uuid, panelKey, gutterSetback , nonGutterSetback, obstructionSetback }= props;
			const data = await getDesignByUUID({uuid, panelKey, gutterSetback, nonGutterSetback, obstructionSetback});
			const { parentAppName, electricUnitPrice, energyDerateRate } = (thunkApi.getState() as RootState).QueryParams.data;
			const energy = (thunkApi.getState() as RootState).energyData.data as EnergyState;
			const appIsEmbeddedInSubhub = parentAppName === SUBHUB_APP_NAME;
			const toggle = { 
				orgToggle: !!data.organization.settings?.showToggleForAllPanels,
				designToggle: data.showToggleForAllPanels || appIsEmbeddedInSubhub
			};
			const { selectedProvider, selectedRoofIndex, drawModeEnabled } = (thunkApi.getState() as RootState).toolNewPostions.data;
			const imagerySource = data.source || selectedProvider;
			const previousBillAmount = (thunkApi.getState() as RootState).energyData.data?.currentBillAmount;
			const billAmount = previousBillAmount || data.billAmount;
			const annualEnergy = getAnnualEnergyFromBillAmount(billAmount, electricUnitPrice);
			const convertedData = getPreviousPanelAllignment(data, annualEnergy, toggle, energyDerateRate, imagerySource, energy.selectedPanels, selectedRoofIndex ?? undefined, drawModeEnabled);
			convertedData.offsetIndex = getOffsetIndex(convertedData.rasterSolarPanels, annualEnergy, energyDerateRate);
			return {
				roofData: convertedData,
				userHasGivenPermToExceedOffset: data.userHasGivenPermToExceedOffset,
				metaData: {
					billAmount,
					annualEnergy,
					electricUnitPrice
				},
				energyDerateRate,
			} as TransformedSolarData;
		} catch (error) {
			console.log('error roofslice ',error);
			throw thunkApi.rejectWithValue(errorResponseHandler(error as AxiosError)) as unknown as CustomError;
		}
	});

const initialState: {
	loading: boolean, error?: CustomError, configuringNewPanel: boolean; data: RoofData, switchingImageryProvider: boolean,
} = {
	loading: false,
	error: undefined,
	configuringNewPanel: false,
	data: getInitialState(),
	switchingImageryProvider: false,
};

export const roofDataSlice = createSlice({
	name: 'roofData',
	initialState,
	reducers: {
		resetRoofData(state, action: PayloadAction<RoofData>) {
			if (!state.data) return;
			state.data = action.payload;
		},
		setOrgData(state, action: PayloadAction<Partial<RoofData['org']['setting']> | null>) {
			if (!state.data) return;
			state.data.org.setting = { ...state.data.org.setting, ...action.payload };
		},
		// setIsSyncedWithParent(state, action: PayloadAction<{ synced: boolean }>){
		// 	state.data.isSyncedWithParent = action.payload.synced;
		// },
	}, extraReducers: (builder,) => {
		builder
			.addCase(fetchRoofData.pending, (state) => {
				state.configuringNewPanel = false;
				state.switchingImageryProvider = false;
				state.loading = true;
				state.error = undefined;
			})
			.addCase(fetchRoofData.fulfilled, (state, action) => {
				state.loading = false;
				state.configuringNewPanel = false;
				state.switchingImageryProvider = false;
				state.data = action.payload.roofData;
			})
			.addCase(fetchRoofData.rejected, (state, action) => {
				state.error = action.payload as CustomError;
				state.loading = false;
				state.configuringNewPanel = false;
				state.switchingImageryProvider = false;
			})
			.addCase(changePanel.pending, (state, action) => {
				const { switchingSource } = action.meta.arg;
				state.switchingImageryProvider = !!switchingSource;
				// eslint-disable-next-line no-extra-boolean-cast
				state.configuringNewPanel = !Boolean(switchingSource);
				state.loading= !!switchingSource;
				state.error = undefined;
			})
			.addCase(changePanel.fulfilled, (state, action) => {
				state.configuringNewPanel = false;
				state.switchingImageryProvider = false;
				state.data = action.payload.roofData;
				state.error = undefined;
				state.loading= false;
			})
			.addCase(changePanel.rejected, (state, action) => {
				state.configuringNewPanel = false;
				state.switchingImageryProvider = false;
				state.error = action.payload as CustomError;
				state.loading= false;
			}).
			addCase(suggestNewPanelAlignment.fulfilled, (state, action) => {
				state.data = action.payload.roofState;
			}).
			addCase(showAllPanels.fulfilled, (state, action) => {
				state.data = action.payload.roofState;
			}).
			addCase(recalcuatedEnergyVals.fulfilled, (state, action) => {
				state.data = action.payload.roofState;
			});
	},
});

export const { resetRoofData, setOrgData } = roofDataSlice.actions;
export default roofDataSlice.reducer;

