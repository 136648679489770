import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type ManualToolModal = {
	open: boolean,
	showAllFacets: boolean;
	inputFieldsEdited: boolean;
};

const initialState: { data: ManualToolModal } = {
	data: {
		open: false,
		showAllFacets: true,
		inputFieldsEdited: false,
	}
};

export const ManualToolModalSlice = createSlice({
	name: 'ManualToolModalProvider',
	initialState,
	reducers: {
		toggleManualToolModalState(state, action: PayloadAction<{ value: boolean, showAll?: boolean, inputFieldsEdited?: boolean }>) {
			state.data.open = action.payload.value;
			state.data.showAllFacets = !!action.payload.showAll;
		},
		toggleManualToolDoneButtonState(state, action: PayloadAction<{ inputFieldsEdited: boolean }>) {
			state.data.inputFieldsEdited = action.payload.inputFieldsEdited;
		}
	}
});

export const { toggleManualToolModalState, toggleManualToolDoneButtonState } = ManualToolModalSlice.actions;

export default ManualToolModalSlice.reducer;