import styled, { css } from 'styled-components';

const sizeStyles = {
	small: css`
    padding: 3px 6px;
    font-size: 12px;
  `,
	medium: css`
    padding: 5px 10px;
    font-size: 14px;
  `,
	large: css`
    padding: 7px 11px;
    font-size: 16px;
  `,
};

const ToggleButtonStyle = styled.button<{ isActive: boolean, size: 'small' | 'medium' | 'large' }>`
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: ${({ isActive }) => (isActive ? '#007bff' : 'white')};
  color: ${({ isActive }) => (isActive ? 'white' : '#333')};
  cursor: pointer;
  transition: all 0.3s ease;
	// min-width: 50px;
	${({ size }) => sizeStyles[size]}

  &:hover {
    background-color: ${({ isActive }) => (isActive ? '#0056b3' : '#f0f0f0')};
  }
`;

const ToggleGroupContainerStyle = styled.div`
  display: flex;
  align-items: center;
  gap: .6rem;
	flex-wrap: wrap;
	justify-content: flex-start;
  align-content: flex-start;
  max-width: 100%;
`;

export { ToggleButtonStyle, ToggleGroupContainerStyle };
