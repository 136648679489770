import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { detectPanelColor, getAllFacetPolygon, resizePoints } from '../util';
import { Line } from 'react-konva';
import { KONVA_FACET_GROUP, PANEL_OFF_COLOR, PANEL_STROKE_COLOR, SEMI_TRANPARENT_BLACK_PANEL, STROKE_COLOR_FOR_USER_MAPPED_PANEL } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateEnergyStateFromPanelClicks } from 'store/slices/EnergySlice/energySlice';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { RootState } from 'store';
import { setIntermediateEnergyState, toggleOffsetState } from 'store/slices/OffsetModelSlice/offsetmodelSlice';
import { useRefs } from 'contexts/RefContext';
import { calculateOffsetPercentage, getDeratedValue } from '../../../util';
import { useKonvaScale } from 'hooks/useKonvaScale';
import { setCurrentMovablePanels, setDeletedPanels } from 'store/slices/PanelSlice';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { checkIntersection, getRotatedLineDimensions, getRotatedLinePoints, getSnappedPosition } from '../PanelUtil';
import { CurrentMovablePanel } from 'store/slices/ToolSlice/types';
import { getAbsolutePoints } from 'components/DisplayEnergy/util';

type Props = {
	panelData: RasterSolarPanel & { userMapped: boolean , azimuth: number};
	panelId: string,
};

function SolarPanel(props: Props) {

	const { panelData: panel, panelId } = props;
	const dispatch = useDispatch();
	const { energy, billEnergy, selectedPanels, deratedBy } = useSelector((state: RootState) => state.energyData.data as unknown as EnergyState);
	const { userHasGivenPermToExceedOffset } = useSelector((state: RootState) => state.offsetModal.data);
	const { editModeEnabled, roofIndexes, userHasClickedSave, saveWithPanelColor, moveStage } 
	= useSelector((state: RootState) => state.toolNewPostions.data);
	const { enablePanelMove, deleteIndividualPanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { org, allRoofSegs } = useSelector((state: RootState) => state.roofData.data);

	const { sliderRef, konvaRef } = useRefs();
	const scale = useKonvaScale();

	const panelIsInEditMode = editModeEnabled || roofIndexes.includes(panel.segmentId);
	const canSelectPanel = !panelIsInEditMode && !moveStage;

	const intersectedFacetRef = useRef<Konva.Line| null >(null);
	const panelRef = useRef<Konva.Line| null >(null);
 
	const [initialPoints, setInitialPoints]= useState<number[]>([]);
	const [color, setColor] = useState(() => {
		if (selectedPanels.includes(panel.id)) return detectPanelColor(panel.yearlyEnergyDcKwh);
		return PANEL_OFF_COLOR;
	});
	
	{
		let panelColor;
		if (panelIsInEditMode)
			panelColor = SEMI_TRANPARENT_BLACK_PANEL;
		else {
			if(userHasClickedSave && !saveWithPanelColor)
				panelColor = selectedPanels.includes(panel.id) ? SEMI_TRANPARENT_BLACK_PANEL : PANEL_OFF_COLOR;
			else
				panelColor = selectedPanels.includes(panel.id) ? detectPanelColor(panel.yearlyEnergyDcKwh) : PANEL_OFF_COLOR;
		}
			
		if (color !== panelColor) {
			setColor(panelColor);
		}
	}

	function updateOffsetModal() {
		let offsetUpdated = false;
		if (color === PANEL_OFF_COLOR) {
			const intermediiateOffset = calculateOffsetPercentage(getDeratedValue((energy + panel.yearlyEnergyDcKwh), deratedBy), billEnergy);
			dispatch(setIntermediateEnergyState({ value: { source: 'panel', data: { panelInfo: { panel, panelId } } } }));
			if (!userHasGivenPermToExceedOffset.offset120 && intermediiateOffset >= 120 && org.setting?.enableOffsetWarning) {
				dispatch(toggleOffsetState({ value: true }));
				offsetUpdated = true;
			}
			if (!userHasGivenPermToExceedOffset.offset150 && intermediiateOffset >= 150 && org.setting?.enableOffsetWarning) {
				dispatch(toggleOffsetState({ value: true }));
				offsetUpdated = true;
			}
		}
		return offsetUpdated;
	}

	function updateSLider(dir: string) {
		if (!sliderRef?.current) return;
		const step = +sliderRef.current.step;
		const sliderVal = +sliderRef.current.value;
		let rangeVl;
		if (dir === 'up') {
			sliderRef.current.value = (sliderVal + step).toString();
			rangeVl = ((sliderVal + step) / +sliderRef.current.max) * 100;
		}
		else {
			sliderRef.current.value = (sliderVal - step).toString();
			rangeVl = ((sliderVal - step) / +sliderRef.current.max) * 100;
		}
		sliderRef.current.style.background = `linear-gradient(to right, var(--secondary) 0%, var(--secondary) ${rangeVl}%, #c4c4c4 ${rangeVl}%, #c4c4c4 100%)`;
	}

	function updateColorAndMetada() {
		const energyBoost = color === PANEL_OFF_COLOR ? panel.yearlyEnergyDcKwh : -panel.yearlyEnergyDcKwh;
		const irradianceBoost = color === PANEL_OFF_COLOR ? panel.irradiance : -panel.irradiance;
		dispatch(updateEnergyStateFromPanelClicks({ energyBoost, panelId, irradianceBoost }));
		setColor((prevState) => (prevState === PANEL_OFF_COLOR ? detectPanelColor(panel.yearlyEnergyDcKwh) : PANEL_OFF_COLOR));
	}

	function handleClick() {
		if(deleteIndividualPanelMode){
			dispatch(setDeletedPanels({data: {panelId, roofSegmentId: panel.segmentId }}));
			return;
		}
		if (panelIsInEditMode) return;
		if (!canSelectPanel) return;
		if (!updateOffsetModal()) {
			updateColorAndMetada();
			updateSLider((color === PANEL_OFF_COLOR) ? 'up' : 'down');
		}
	}

	const panelPoints = useMemo(() => {
		return resizePoints(panel.exteriorCoords.flat(), scale);
	},[scale, panel.exteriorCoords, enablePanelMove]);

	// const [panelPoints, setPanelPoints] = useState(() =>
	// 	resizePoints(panel.exteriorCoords.flat(), scale)
	// );
	
	useEffect(() => {
		if (!enablePanelMove && panelRef?.current && konvaRef?.current) {
			const originFacet = konvaRef?.current?.findOne(`#${panel.segmentId}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}${KONVA_FACET_GROUP.EXISTING_GROUP_NAME}`);
			// if(originFacet && originFacet.name() !== panelRef.current.getParent()?.name() ){
			panelRef.current.moveTo(originFacet);
			// }	
			panelRef.current.setPosition({x:0, y: 0});

		}
	}, [enablePanelMove, panelRef, panel.segmentId, konvaRef]);

	const strokeColor = useMemo(() => {
		return (panel?.userMapped && editModeEnabled) ? STROKE_COLOR_FOR_USER_MAPPED_PANEL : PANEL_STROKE_COLOR;
	}, [editModeEnabled, panel?.userMapped]);
	
	const handlePointerDown = (e:any) => {
		const shape = e.target;
		if(panelRef.current)
			panelRef.current.attrs.position = shape.position();
		setInitialPoints(shape.points());

	};
	
	const handleDragMove= useCallback (function(e: KonvaEventObject<DragEvent>) {
		if(!konvaRef?.current) return;
		const shape = e.target as Konva.Line; // The shape being dragged (a Line)
		const currentPos = shape.position();
		intersectedFacetRef.current = null;

		const allFacets= getAllFacetPolygon(konvaRef.current);
		const parentFacet = checkIntersection(allFacets, shape);

		if(!parentFacet) return;
		intersectedFacetRef.current = parentFacet as Konva.Line;
		const newGroup = (intersectedFacetRef.current.getParent());
		shape.moveTo(newGroup);
		const siblings = intersectedFacetRef.current.parent?.children?.slice(1).filter((eachChild: any) => eachChild.id() !== shape.id());
		const rs = allRoofSegs.find(r => r.id === intersectedFacetRef.current?.id());
		if(rs && siblings?.[0]){
			let orientationRotation = 0;
			console.log(panel.orientation, rs.orientation);
			if(rs.orientation !== panel.orientation) {
				orientationRotation = 90;
			}
			const { width, height } = getRotatedLineDimensions(siblings[0] as Konva.Line, rs.azimuthDegrees);
			const points = getRotatedLinePoints(e.target.getRelativePointerPosition(), width, height, rs.azimuthDegrees + orientationRotation);
			shape.points(points);

			// Perform snapping/collision check using SAT.js
			const snappedPosition = getSnappedPosition(currentPos, shape, siblings);
		
			// Set the new position for snapping/collision resolution (if needed)
			if (snappedPosition) {
				shape.position(snappedPosition);
			}
		}
	},[scale, allRoofSegs]);

	function handleDragEnd (e: KonvaEventObject<DragEvent>){
		if(!konvaRef?.current) return;
		const shape = e.target as Konva.Line;
		
		const allFacets= getAllFacetPolygon(konvaRef.current);
		const parentFacet = checkIntersection(allFacets, shape);

		if(!parentFacet && panelRef.current) {
			shape.points(initialPoints);
			panelRef.current.attrs.position && shape.position(panelRef.current.attrs.position);
			return;
		}

		if(!shape || !konvaRef?.current || !intersectedFacetRef?.current) return;

		const rs= allRoofSegs.find(r=>r.id=== intersectedFacetRef.current?.id());
		if(!rs) return;
		const exteriorCoords: number[][] = [];
		const coords = getAbsolutePoints(shape, scale, konvaRef?.current?.position());
		for (let i = 0; i < coords.length; i += 2) {
			exteriorCoords.push([coords[i], coords[i + 1]]);
		}
		const movedPanelCongif: CurrentMovablePanel= {
			orientation: rs.orientation,
			originSegmentId: panel.segmentId,
			exteriorCoords,
			panelId: shape.id(),
			roofSegmentId: rs.id
		};

		dispatch(setCurrentMovablePanels({currentMovablePanel: movedPanelCongif}));
	}

	return (
		<Line
			ref= {panelRef}
			id={panel.id}
			stroke={strokeColor}
			closed={true}
			strokeWidth={1.4}
			points={panelPoints}
			fill={color}
			onClick={handleClick}
			onTap={handleClick}
			draggable={enablePanelMove}
			onPointerDown={handlePointerDown}
			onDragMove={handleDragMove}
			onDragEnd={handleDragEnd}
		/>
	);
}

export default memo(SolarPanel);

