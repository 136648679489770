import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleOrientationModalState } from 'store/slices/ChangeOrientationModalSlice';
import { useQuery } from 'react-query';
import { changeFacetOrientation } from './api';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { toast } from 'react-toastify';
import { toggleWarningModalState } from 'store/slices/WarningSlice';

const popupMsg = 'Are you sure you want to change the facet\'s orientation?';
export default function ChangeOrientation() {
	const { open } = useSelector((state: RootState) => state.changeOrientationModal.data);
	const { selectedRoofIndex, deletedRoofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { allRoofSegs, uuid, panel } = useSelector((state: RootState) => state.roofData.data);

	const dispatch = useDispatch<AppDispatch>();

	const changeFacetOrientationQuery = useQuery(
		'changeFacetOrientation',
		async () => {
			if (!selectedRoofIndex) return;
			const selectedRoof = allRoofSegs.find(r => r.id === selectedRoofIndex);
			const panelsCount = await changeFacetOrientation(
				selectedRoofIndex, uuid, panel.key, selectedRoof?.orientation as string, !!selectedRoof?.userMapped
			);
			return { panelsCount, selectedRoofIndex };
		},
		{
			onSuccess: async (data: { panelsCount: number, selectedRoofIndex: string }) => {
				if (data?.panelsCount == 0) {
					dispatch(toggleWarningModalState({
						open: true,
						warningMessage: 'We are not able to generate any panel in this orientation.',
						selectedRoofIdx: data?.selectedRoofIndex,
						noOfPanels: data?.panelsCount
					}));
				}
				else if (data?.panelsCount < 3) {
					dispatch(toggleWarningModalState({
						open: true,
						warningMessage: 'We are not able to generate more than 2 panels in this orientation, do you still want to continue?',
						selectedRoofIdx: data?.selectedRoofIndex,
						noOfPanels: data?.panelsCount
					}));
				}
				await dispatch(changePanel({ uuid, panelKey: panel.key }));
				dispatch(toggleOrientationModalState({ open: false, isLoading: false }));
			},
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onError: (error: Error) => {
				toast.error('something went wrong, please try again');
			},
			retry: 0,
			enabled: false,
		}
	);

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				if (open)
					dispatch(toggleOrientationModalState({ open: false, isLoading: false }));
			}}>
			<ModalPopup>
				<ModalcontentInfo>
					{popupMsg}
				</ModalcontentInfo>
				<ModalbuttonWrap>
					<Modalbutton onClick={() => {
						if (!selectedRoofIndex) return;
						if (deletedRoofIndexes.includes(selectedRoofIndex)) return;
						changeFacetOrientationQuery.refetch();
						dispatch(toggleOrientationModalState({ open: false, isLoading: true }));
					}}>
						Yes
					</Modalbutton>
					<ModalbuttonNo onClick={() => {
						dispatch(toggleOrientationModalState({ open: false, isLoading: false }));
					}}>
						No
					</ModalbuttonNo>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}